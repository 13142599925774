import { useEffect } from "react";
import { Heading, StepStatus, Text } from "@allica/ui-react";

import { Box, useDisclosure } from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { joiResolver } from "@hookform/resolvers/joi";
import { Modal } from "../../../../components/modal/Modal";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { Stage, BusinessRewardsApiType } from "../../BusinessRewards.types";
import { handleSignUpError } from "src/shared/sign-up/SignUp.utils";
import { signUpSchema } from "src/shared/sign-up/SignUp.validation";
import { SignUpFormValues } from "src/shared/sign-up/SignUp.types";
import { SignUpForm } from "src/shared/sign-up/SignUpForm";
import { Overlay } from "src/components/overlay/Overlay";
import { useStore } from "src/core/store/StoreContext";
import {
  SignUpErrorFields,
  SignUpResponseType,
} from "src/core/service/deposits-api/DepositsApi.types";
import { DepositsAPI } from "src/core/service";
import { getUtmParameters } from "src/core/utils/cookies/cookies";
import environment from "src/environments/environment";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { ReCaptchaErrorModal } from "src/components/recaptcha-error-modal/ReCaptchaErrorModal";

export const BusinessRewardsSignUp = () => {
  const {
    businessRewardsData,
    setBusinessRewardsData,
    setCurrentStage,
    setShowGenericError,
    updateStepperConfig,
  } = useBusinessRewardsContext();
  const { storeStatus } = useStore();

  const formMethods = useForm<SignUpFormValues>({
    resolver: joiResolver(signUpSchema),
  });

  const { getValues, setError, reset } = formMethods;

  const { isOpen: showModal, onOpen: openModal, onClose: closeModal } = useDisclosure();

  const {
    isOpen: showRecaptchaErrorModal,
    onOpen: openRecaptchaErrorModal,
    onClose: closeRecaptchaErrorModal,
  } = useDisclosure();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    response: saveResponse,
    status: saveReqStatus,
    request: saveData,
    error: saveError,
  } = DepositsAPI<SignUpResponseType, SignUpErrorFields>("applications/signup");

  const navigateToOB = () => {
    window.location.replace(environment.ui.onlineBanking);
  };

  const onSubmit: SubmitHandler<SignUpFormValues> = (values) => {
    setShowGenericError(false);
    const { emailAddress, phoneNumber, password } = values;
    const utm = getUtmParameters();

    const payload: BusinessRewardsApiType["signUpSection"] = {
      emailAddress,
      phoneNumber,
      password,
      domain: "BRA",
      ...(isFeatureActive(FeatureFlag.GTM) && { utm }),
    };
    if (environment.googleReCAPTCHA.enabled && executeRecaptcha) {
      executeRecaptcha("submit").then((token: string) => {
        saveData({
          method: "POST",
          body: JSON.stringify({ ...payload, captchaToken: token }),
        });
      });
    } else {
      if (environment.googleReCAPTCHA.enabled) {
        openRecaptchaErrorModal();
      }

      saveData({
        method: "POST",
        body: JSON.stringify({ ...payload, captchaToken: "" }),
      });
    }
  };

  useEffect(() => {
    const { emailAddress, password, phoneNumber } =
      businessRewardsData?.businessRewardsApplicationSections?.signUpSection;

    reset({
      reTypeEmail: emailAddress,
      emailAddress,
      password,
      reTypePassword: password,
      phoneNumber,
    });
  }, [businessRewardsData?.businessRewardsApplicationSections?.signUpSection]);

  useEffect(() => {
    if (saveReqStatus.success) {
      const newData = { ...businessRewardsData };
      const [emailAddress, phoneNumber, password] = getValues([
        "emailAddress",
        "phoneNumber",
        "password",
      ]);

      const values: BusinessRewardsApiType["signUpSection"] = {
        emailAddress,
        phoneNumber,
        password,
        domain: "BRA",
      };

      const noOfSecurityCodeSent =
        (newData?.businessRewardsApplicationSections?.verifySection?.noOfSecurityCodeSent || 0) + 1;
      newData.businessRewardsApplicationSections.verifySection = { noOfSecurityCodeSent };
      newData.businessRewardsApplicationSections.signUpSection = values;
      newData.applicationID = saveResponse.applicationRef;
      setBusinessRewardsData(newData);

      updateStepperConfig([
        { stage: Stage.SIGN_UP, value: { status: StepStatus.COMPLETE } },
        { stage: Stage.VERIFY, value: { status: StepStatus.INCOMPLETE } },
      ]);
      setCurrentStage(Stage.VERIFY);
    }
  }, [saveReqStatus.success]);

  useEffect(() => {
    if (saveReqStatus.error) {
      handleSignUpError(saveError, setError, openModal, setShowGenericError);
    }
  }, [saveReqStatus.error]);

  useEffect(() => {
    if (environment.googleReCAPTCHA.enabled && !executeRecaptcha) {
      openRecaptchaErrorModal();
    }
  }, []);

  return (
    <>
      {!storeStatus?.success && <Overlay spinner />}
      <Box mt="6.4rem">
        <Heading color="$text-01" size="h1" as="h1">
          Sign up details
        </Heading>

        <FormProvider {...formMethods}>
          <SignUpForm isBusiness={true} isSubmitting={saveReqStatus.loading} onSubmit={onSubmit} />
        </FormProvider>

        <Modal
          headerTitle="Email already in use"
          primaryButtonText="Login"
          primaryAction={navigateToOB}
          secondaryButtonText="Cancel"
          isOpen={showModal}
          onClose={closeModal}
          variant="error"
          heapId={{
            modalClose: "Existing-X-button",
            secondaryAction: "Existing-cancel-button",
            primaryAction: "Existing-login-button",
          }}
        >
          <Text as="p" textStyle="body-03" color="$text-02">
            Login to your account to complete the application.
          </Text>
        </Modal>

        <ReCaptchaErrorModal
          isOpen={showRecaptchaErrorModal}
          onClose={closeRecaptchaErrorModal}
          primaryAction={closeRecaptchaErrorModal}
        />
      </Box>
    </>
  );
};
