import { ConfirmationOfPayeeResponse } from "src/core/service/confirmation-of-payee-api/ConfirmationOfPayeeApi.types";
import { PersonalSavingDataTypes } from "../PersonalSavings.types";

export const initialPersonalSavingData: PersonalSavingDataTypes = {
  cobApplicationId: "",
  applicationID: "",
  currentApplicationStage: null,
  individualApplicationSections: {
    signUpSection: {
      emailAddress: "",
      phoneNumber: "",
      productReference: "",
      productIssueId: "",
      password: "",
      domain: "INDIVIDUAL",
    },
    aboutYouSection: {
      title: "",
      firstName: "",
      middleName: null,
      lastName: "",
      dateOfBirth: "",
      countryOfBirth: "",
      nationalities: [],
      taxResidencies: [
        {
          taxCountryCode: "GB",
          number: "NA",
        },
      ],
    },
    addressSection: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      county: "",
      postCode: "",
      countryIsoCode: "GB",
    },
    depositSection: {
      depositAmount: "",
      depositDuration: "NA",
    },
    accountSection: {
      name: "",
      sortCode: "",
      accountNumber: "",
      copResponse: {} as ConfirmationOfPayeeResponse,
    },
    documentSection: { terms: false, email: false, sms: false, phone: false, mail: false },
    summarySection: {
      detailsVerified: false,
    },
    outcome: {
      success: false,
    },
  },
};
