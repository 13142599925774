import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../app/ApplicationInsights";
import { StatusCollectionType, TrackApiErrorType } from "./Service.types";
import environment from "src/environments/environment";

export const randomUUID = () => {
  let date = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx".replace(/[x]/g, function (xChar) {
    const randomNumber = (date + Math.random() * 16) % 16 | 0;
    date = Math.floor(date / 16);
    return (xChar === "x" ? randomNumber : (randomNumber & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const getSessionID = () => {
  let sessionID = sessionStorage.getItem("X-Session-Id");
  if (!sessionID || (sessionID && sessionID.length > 30)) {
    sessionID =
      Math.random().toString(36).substring(2) + "I" + new Date().toISOString().replace(/\D/g, "");
    sessionStorage.setItem("X-Session-Id", sessionID);
  }
  return sessionID;
};

export const statusCollection: StatusCollectionType = {
  pending: { pending: true, loading: false, success: false, error: false },
  loading: { pending: false, loading: true, success: false, error: false },
  success: { pending: false, loading: false, success: true, error: false },
  error: { pending: false, loading: false, success: false, error: true },
};

export const sessionExpired = () => {
  window.location.href = environment.ui.allicaPortal + "/logout";
};

export const trackApiException = ({
  endpoint,
  method,
  headers,
  errorResponse,
}: TrackApiErrorType) => {
  const { Authorization, ...restOfHeaders } = headers;
  delete restOfHeaders["Content-Type"];

  const additionalInfo = {
    endpoint,
    method: method ?? "GET",
    headers: restOfHeaders,
    errorResponse: errorResponse.status
      ? errorResponse
      : { status: 99, text: "Unable to load results", info: errorResponse?.toString() },
    url: window.location.href,
  };

  appInsights.trackException({
    exception: new Error(`service-error | ${headers["X-Session-Id"]}`),
    severityLevel: errorResponse.status < 500 ? SeverityLevel.Information : SeverityLevel.Error,
    properties: additionalInfo,
  });
};
