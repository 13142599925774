export const getAllMaskedIndexes = (maskedPattern: string, maskedChar: string) => {
  return maskedPattern
    ?.split("")
    ?.reduce((acc: number[], el: string, i: number) => (el === maskedChar ? [...acc, i] : acc), []);
};

export const getMaskedValue = (
  value: string,
  allMaskedIndexes: number[],
  previousValue: string,
  maskedPattern: string,
  maskedValue: string
): string => {
  const maxLength = maskedPattern?.length || 0;
  if (!value && maxLength === value.length) {
    return "";
  }
  if (
    !allMaskedIndexes?.includes(maskedValue.length) &&
    previousValue !== maskedPattern[maskedValue.length]
  ) {
    let idx = maskedValue?.length;
    while (idx < maxLength) {
      if (!allMaskedIndexes?.includes(idx)) {
        maskedValue += maskedPattern[idx];
        idx++;
      } else {
        break;
      }
    }
  }
  return maskedValue;
};

export const getUserInputValue = (
  value: string,
  allMaskedIndexes: number[],
  maskedPattern: string
) => {
  let idx = 0;
  let newValue = value;
  if (!value) return "";
  while (idx < value?.length) {
    if (!allMaskedIndexes?.includes(idx)) {
      newValue = newValue.replace(maskedPattern[idx], "");
    }
    idx++;
  }
  return newValue;
};

export const maskedFormatValue = (
  userInput: string,
  maskedPattern: string,
  allMaskedIndexes: number[]
) => {
  const maxLength = maskedPattern?.length || 0;
  let idx = 0;
  if (!userInput) return "";
  let maskedValue = userInput?.split("")?.slice(0, allMaskedIndexes.length).join("");
  while (idx < maxLength - 1) {
    if (!allMaskedIndexes?.includes(idx)) {
      maskedValue = maskedValue.substring(0, idx) + maskedPattern[idx] + maskedValue.substring(idx);
    }
    idx++;
  }
  return maskedValue;
};
