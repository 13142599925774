import { Button, ChevronRightIcon } from "@allica/ui-react";
import { Stack } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import environment from "src/environments/environment";
import { BaseInput } from "../../components/input/default/Input";
import { FormGroup } from "../../components/input-set/FormGroup";
import { PasswordInput } from "../../components/input/password/PasswordInput";
import InternationalPhoneInput from "src/components/input/international-phone/InternationalPhoneInput";
import { PasswordWithCheckList } from "src/components/input/password-with-checklist/PasswordWithCheckList";
import { SignUpFormProps, SignUpFormValues } from "src/shared/sign-up/SignUp.types";
import { ClipboardEventHandler } from "react";

export const SignUpForm = ({ isBusiness, onSubmit, isSubmitting }: SignUpFormProps) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useFormContext<SignUpFormValues>();

  const handlePaste: ClipboardEventHandler<HTMLInputElement> = (event) =>
    environment.production && event?.preventDefault();

  const emailLabel = isBusiness ? "Business email" : "Email";
  const retypeEmailLabel = isBusiness ? "Re-type business email" : "Re-type email";

  const emailFieldDataHeapId = (type: string | undefined) => {
    if (type === "string.empty") {
      return "email-empty-error";
    }

    if (type) {
      return "email-valid-error";
    }

    return undefined;
  };

  const phoneNumberFieldDataHeapId = (type: string | undefined) => {
    if (type === "string.empty") {
      return "phone-empty-error";
    }

    if (type) {
      return "phone-valid-error";
    }

    return undefined;
  };

  const passwordFieldDataHeapId = (type: string | undefined) => {
    if (type === "string.empty") {
      return "password-empty-error";
    }

    if (type) {
      return "password-valid-error";
    }

    return undefined;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Stack spacing="3.2rem" mt="6.4rem">
        <FormGroup
          label={emailLabel}
          error={errors?.emailAddress?.message}
          data-heapid={emailFieldDataHeapId(
            !!errors.emailAddress ? (errors.emailAddress.type as string) : undefined,
          )}
          isRequired
        >
          <BaseInput
            {...register("emailAddress")}
            type="email"
            isInvalid={!!errors?.emailAddress}
            data-heapid="email-business-enter"
          />
        </FormGroup>

        <FormGroup
          label={retypeEmailLabel}
          error={errors?.reTypeEmail?.message}
          data-heapid={!!errors?.reTypeEmail ? "email-match-error" : undefined}
          isRequired
        >
          <BaseInput
            {...register("reTypeEmail")}
            type="email"
            autoComplete="off"
            isInvalid={!!errors?.reTypeEmail}
            onPaste={handlePaste}
            data-heapid="email-retype-enter"
          />
        </FormGroup>

        <Controller
          control={control}
          name="password"
          defaultValue=""
          render={({ field }) => (
            <PasswordWithCheckList
              errorMsg={errors?.password?.message}
              autoComplete="new-password"
              {...field}
              data-heapid={passwordFieldDataHeapId(
                !!errors.password ? (errors.password.type as string) : undefined,
              )}
            />
          )}
        />

        <FormGroup
          label="Re-type Password"
          error={errors?.reTypePassword?.message}
          data-heapid={!!errors?.reTypePassword ? "password-match-error" : undefined}
          isRequired
        >
          <Controller
            control={control}
            name="reTypePassword"
            defaultValue=""
            render={({ field }) => (
              <PasswordInput
                autoComplete="off"
                data-testid="reTypePassword"
                isInvalid={!!errors?.reTypePassword}
                onPaste={handlePaste}
                {...field}
                data-heapid="password-retype-enter"
              />
            )}
          />
        </FormGroup>

        <FormGroup
          label="Phone number"
          error={errors?.phoneNumber?.message}
          info={
            "Everytime you log in to Online banking we'll send your verification codes to this number."
          }
          data-heapid={phoneNumberFieldDataHeapId(
            !!errors.phoneNumber ? (errors.phoneNumber.type as string) : undefined,
          )}
          isRequired
        >
          <Controller
            control={control}
            defaultValue=""
            name="phoneNumber"
            render={({ field }) => (
              <InternationalPhoneInput w="31.2rem" isInvalid={!!errors?.phoneNumber} {...field} />
            )}
          />
        </FormGroup>
      </Stack>

      <Button
        isLoading={isSubmitting}
        loadingText="Save and continue"
        spinnerPlacement="end"
        type="submit"
        float="right"
        mt="8rem"
        padding="2.4rem 3.2rem"
        rightIcon={<ChevronRightIcon />}
        data-heapid="save-continue-button"
      >
        Save and continue
      </Button>
    </form>
  );
};
