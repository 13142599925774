import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Input } from "@allica/ui-react";
import { InputProps } from "@chakra-ui/react";
import {
  getAllMaskedIndexes,
  getMaskedValue,
  getUserInputValue,
  maskedFormatValue,
} from "./MaskedInput.utils";

type Ref = HTMLInputElement;

interface Props extends InputProps {
  maskedPattern: string;
  maskedChar: string;
  onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  validate?: (value: string) => boolean;
}

const MaskedInput = forwardRef<Ref, Props>(
  ({ maskedPattern, maskedChar, onChangeHandler, validate, ...rest }, forwardedRef) => {
    const inputRef = useRef<HTMLInputElement>(null);
    let previousValue = "";
    const maxLength = maskedPattern?.length || 0;
    const allMaskedIndexes = getAllMaskedIndexes(maskedPattern, maskedChar);
    const [isPastedValue, setIsPastedValue] = useState(false);

    useImperativeHandle(forwardedRef, () => inputRef?.current as HTMLInputElement);

    useEffect(() => {
      if (inputRef?.current?.value) {
        const currentValue = getUserInputValue(
          inputRef?.current?.value,
          allMaskedIndexes,
          maskedPattern,
        );
        let maskedValue = maskedFormatValue(currentValue, maskedPattern, allMaskedIndexes);
        if (typeof validate === "function") {
          if (!validate?.(currentValue)) {
            maskedValue = "";
          }
        }
        inputRef.current.value = maskedValue;
      }
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e?.target;
      let maskedValue = "";
      if (isPastedValue) {
        const userValue = getUserInputValue(value, allMaskedIndexes, maskedPattern);
        maskedValue = maskedFormatValue(userValue, maskedPattern, allMaskedIndexes);
        if (typeof validate === "function") {
          if (!validate?.(userValue)) {
            maskedValue = "";
          }
        }
        setIsPastedValue(false);
      } else {
        const userValue = getUserInputValue(value, allMaskedIndexes, maskedPattern);
        maskedValue = value;
        if (
          !allMaskedIndexes?.includes(value.length - 1) &&
          value[value?.length - 1] !== maskedPattern[value?.length - 1]
        ) {
          maskedValue =
            value?.slice(0, value?.length - 1) +
            maskedPattern[value?.length - 1] +
            value[value?.length - 1];
        }
        if (typeof validate === "function") {
          if (!validate?.(userValue)) {
            maskedValue = value
              .split("")
              .slice(0, value.length - 1)
              .join("");
          }
        }
        maskedValue = getMaskedValue(
          value,
          allMaskedIndexes,
          previousValue,
          maskedPattern,
          maskedValue,
        );
      }
      previousValue = maskedValue[maskedValue.length - 1];
      inputRef.current!.value = maskedValue;
      onChangeHandler?.(e);
    };

    return (
      <Input
        ref={inputRef}
        maxLength={maxLength}
        onChange={handleChange}
        onPaste={() => setIsPastedValue(true)}
        width={rest?.width || "100%"}
        {...rest}
        type="text"
        bg="$ui-02"
      />
    );
  },
);

export default MaskedInput;
