import { Icon, IconProps } from "@chakra-ui/react";

export const SearchIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9.75C15 12.6495 12.6495 15 9.75 15C6.85051 15 4.5 12.6495 4.5 9.75C4.5 6.85051 6.85051 4.5 9.75 4.5C12.6495 4.5 15 6.85051 15 9.75ZM13.9633 15.0239C12.8085 15.9476 11.3438 16.5 9.75 16.5C6.02208 16.5 3 13.4779 3 9.75C3 6.02208 6.02208 3 9.75 3C13.4779 3 16.5 6.02208 16.5 9.75C16.5 11.3438 15.9476 12.8085 15.0239 13.9633L19.9437 18.8831L18.8831 19.9437L13.9633 15.0239Z"
      fill="currentColor"
    />
  </Icon>
);
