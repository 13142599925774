import { StepStatus } from "@allica/ui-react";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";

import { PersonalSavingDataTypes, Stage } from "../../PersonalSavings.types";
import { DepositsAPI } from "src/core/service";
import { usePersonalContext } from "../../context/PersonalContext";
import { VerifyFormValues, VerifyUIProps } from "src/shared/verify/Verify.types";
import {
  VerifyErrorFields,
  VerifyResponseType,
} from "src/core/service/deposits-api/DepositsApi.types";
import VerifyUI from "src/shared/verify/VerifyUI";

const Verify = () => {
  const [noOfSecurityCodeSent, setNoOfSecurityCodeSent] = useState<number>(1);

  const {
    stepperConfig,
    personalSavingData,
    setPersonalSavingData,
    setCurrentStage,
    setStepperConfig,
    setShowGenericError,
  } = usePersonalContext();

  const { phoneNumber, password } =
    personalSavingData?.individualApplicationSections?.signUpSection;

  const formMethods = useForm<VerifyFormValues>();
  const { setError } = formMethods;

  const {
    response: saveResponse,
    status: saveReqStatus,
    request: saveData,
    error: saveError,
  } = DepositsAPI<VerifyResponseType, VerifyErrorFields>(
    `applications/${personalSavingData?.applicationID}/otp`
  );

  const navigateToSignUp = () => {
    setCurrentStage(Stage.SIGN_UP);
  };

  const onSubmit: SubmitHandler<VerifyFormValues> = (values) => {
    setShowGenericError(false);
    const { otp } = values;
    const payload = { password, otp };
    saveData({
      method: "POST",
      body: JSON.stringify(payload),
    });
  };

  useEffect(() => {
    const savedNoOfSecurityCodeSent =
      personalSavingData?.individualApplicationSections?.verifySection?.noOfSecurityCodeSent;

    savedNoOfSecurityCodeSent !== noOfSecurityCodeSent &&
      setNoOfSecurityCodeSent(savedNoOfSecurityCodeSent ?? 1);
  }, [personalSavingData?.individualApplicationSections?.verifySection?.noOfSecurityCodeSent]);

  useEffect(() => {
    if (saveReqStatus.success) {
      sessionStorage.setItem("token", saveResponse.accessToken);
      sessionStorage.setItem("refreshToken", saveResponse.refreshToken);
      const encodedApplicationId = window.btoa(personalSavingData?.applicationID);
      sessionStorage.setItem("applicationID", encodedApplicationId.toString());

      const newSigUpData = { ...personalSavingData };
      newSigUpData.individualApplicationSections.signUpSection = {
        ...newSigUpData.individualApplicationSections.signUpSection,
        password: "",
      };
      setPersonalSavingData(newSigUpData);

      const newState = { ...stepperConfig };
      newState[Stage.SIGN_UP].disabled = true;
      newState[Stage.VERIFY].status = StepStatus.COMPLETE;
      newState[Stage.VERIFY].disabled = true;
      newState[Stage.ABOUT_YOU].status = StepStatus.INCOMPLETE;
      setStepperConfig(newState);

      setCurrentStage(Stage.ABOUT_YOU);
    }
  }, [saveReqStatus.success]);

  useEffect(() => {
    if (saveReqStatus.error) {
      if (saveError.code === "VALIDATION_ERROR") {
        saveError?.errors?.forEach((data) => {
          const { field, reason } = data;
          setError(field, { message: reason });
        });
      } else if (
        saveError.code &&
        !["404", "503", "CJDEPT_224", "CJDEPT_213"].includes(saveError.code)
      ) {
        setError("otp", { message: saveError.message });
      } else setShowGenericError(true);
    }
  }, [saveReqStatus.error]);

  const VerifyUIProps: VerifyUIProps<PersonalSavingDataTypes> = {
    setShowGenericError,
    navigateToSignUp,
    noOfSecurityCodeSent,
    setNoOfSecurityCodeSent,
    phoneNumber,
    onSubmit,
    isSubmitting: saveReqStatus.loading,
    savingData: personalSavingData,
    setSavingData: setPersonalSavingData,
  };

  return (
    <FormProvider {...formMethods}>
      <VerifyUI<PersonalSavingDataTypes> {...VerifyUIProps} />
    </FormProvider>
  );
};

export default Verify;
