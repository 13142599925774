import { Button, SearchIcon } from "@allica/ui-react";
import { Box } from "@chakra-ui/react";
import AddressSearch from "./address-search/AddressSearch";
import ManualAddress from "./manual-address/ManualAddress";
import { AddressWrapperProps } from "./AddressWrapper.types";
import { useFormContext } from "react-hook-form";

const AddressWrapper = ({
  showManualAdd,
  setShowManualAdd,
  addressTypeLabel,
  addressHelperText,
  fieldRootPath,
  heapId,
}: AddressWrapperProps) => {
  const rootPath = fieldRootPath ? `${fieldRootPath}.` : "";
  const { clearErrors } = useFormContext();

  return !showManualAdd ? (
    <>
      <AddressSearch
        label={addressTypeLabel}
        helperText={addressHelperText}
        setShowManualAdd={setShowManualAdd}
        fieldRootPath={rootPath}
        heapId={{ onSearch: heapId?.onAddressSearchEnter, onEmptyError: heapId?.onEmptyError }}
      />
      <Box>
        <Button
          variant="text"
          onClick={() => {
            setShowManualAdd(!showManualAdd);
            clearErrors(fieldRootPath); // will clear errors
          }}
          data-heapid={heapId?.onManualButtonClick}
        >
          Enter address manually
        </Button>
      </Box>
    </>
  ) : (
    <>
      <Box mb="3.2rem">
        <Button
          variant="text"
          leftIcon={<SearchIcon boxSize="2.4rem" />}
          iconSpacing="0.8rem"
          onClick={() => {
            setShowManualAdd(!showManualAdd);
            clearErrors(fieldRootPath); // will clear errors
          }}
        >
          Search for a new address
        </Button>
      </Box>
      <ManualAddress fieldRootPath={rootPath} />
    </>
  );
};

export default AddressWrapper;
