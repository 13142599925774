// For now the steps are hard-coded to allow navigation freely between steps
// we should control this state in future based on responses from the backend.
// It might be more suitable to use a Map rather than an object when it comes
// to updating the state

import { Steps, StepStatus } from "@allica/ui-react";
import { Stage } from "../PersonalSavings.types";

export const initialStepperConfig: Steps<Stage> = {
  [Stage.SIGN_UP]: {
    label: "Sign Up",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.VERIFY]: {
    label: "Verify",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.ABOUT_YOU]: {
    label: "About",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.ADDRESS]: {
    label: "Address",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.DEPOSIT]: {
    label: "Deposit",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.ACCOUNT]: {
    label: "Account",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.DOCUMENT]: {
    label: "Documents",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.SUMMARY]: {
    label: "Summary",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
};
