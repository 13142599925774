import { Stage } from "../PersonalSavings.types";

export const stepOrder: Stage[] = [
  Stage.SIGN_UP,
  Stage.VERIFY,
  Stage.ABOUT_YOU,
  Stage.ADDRESS,
  Stage.DEPOSIT,
  Stage.ACCOUNT,
  Stage.DOCUMENT,
  Stage.SUMMARY,
];
