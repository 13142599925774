import { Icon, IconProps } from "@chakra-ui/react";

export const BinIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.875 1.25H13.125V2.5H6.875V1.25ZM2.5 5V3.75H5H15H17.5V5H16.25L15.625 17.5C15.625 18.1904 15.0654 18.75 14.375 18.75H5.625C4.93464 18.75 4.375 18.1904 4.375 17.5L3.75 5L2.5 5ZM5 5L5.625 17.5H14.375L15 5H5ZM11.25 7.5H12.5V15H11.25V7.5ZM8.75 7.5H7.5V15H8.75V7.5Z"
      fill="currentColor"
    />
  </Icon>
);
