import { FormControl, FormLabel, FormHelperText, FormErrorMessage, Text } from "@allica/ui-react";
import { FormControlProps } from "@chakra-ui/react";
import { ReactElement } from "react";

interface IFormGroup extends FormControlProps {
  label: string;
  info?: string | ReactElement;
  error?: string;
}

export const FormGroup = ({ label, info, error, children, ...rest }: IFormGroup) => {
  return (
    <FormControl isInvalid={!!error} {...rest}>
      <FormLabel>
        {label}
        {!rest.isRequired && label && (
          <Text ml="0.4rem" color="$text-03" as="abbr">
            (Optional)
          </Text>
        )}
      </FormLabel>
      {info && <FormHelperText>{info}</FormHelperText>}
      {children}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
