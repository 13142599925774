import { Steps, StepStatus } from "@allica/ui-react";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { deepClone } from "src/core/utils";
import { FeatureFlag } from "src/environments/feature.flags";
import { AlertStage, Stage } from "../../BusinessRewards.types";
import {
  businessRewardsInitialStepperConfig,
  businessRewardsInitialStepperConfigMVP,
} from "../businessRewardsInitialStepperConfig";
import { PARTIES_STEP_ORDER_INDEX } from "./businessRewardsContext.constants";

const isPhaseTwo = isFeatureActive(FeatureFlag.BRA_PHASE_TWO);

export const mapBusinessRewardsStepper = (
  stageFromApi: Stage,
  alertStageList: Stage[],
  stepOrder: Stage[],
) => {
  const initialStepperConfig = isPhaseTwo
    ? businessRewardsInitialStepperConfig
    : businessRewardsInitialStepperConfigMVP;

  // deep clone to avoid mutation
  const stepperConfig = deepClone(initialStepperConfig) as Steps<Stage>;
  const alertStages: AlertStage[] = [];

  for (let index = 0; index < stepOrder.length; index++) {
    if (stepOrder[index] === stageFromApi) {
      stepperConfig[stepOrder[index]].status = StepStatus.INCOMPLETE;
      break;
    } else if (alertStageList.includes(stepOrder[index])) {
      stepperConfig[stepOrder[index]].status = StepStatus.ALERT;
      alertStages.push(stepOrder[index] as AlertStage);
    } else {
      stepperConfig[stepOrder[index]].status = StepStatus.COMPLETE;
    }
    // ensures step is completed when not in the stepOrder after passing the point of which it can be included
    if (index === PARTIES_STEP_ORDER_INDEX && stepOrder[index] !== Stage.PARTIES) {
      stepperConfig[Stage.PARTIES].status = StepStatus.COMPLETE;
    }
  }

  stepperConfig[Stage.SIGN_UP].disabled = true;
  stepperConfig[Stage.VERIFY].disabled = true;
  return { stepperConfig, currentStep: stageFromApi, alertStages };
};
