import { Heading, Text } from "@allica/ui-react";
import { Box, Flex, OrderedList } from "@chakra-ui/react";
import { EditIcon } from "../icon";
import SummaryListItem from "../summary-list-item/SummaryListItem";
import { SummaryListProps } from "./SummaryList.types";

const SummaryList = ({ title, listItems, onEdit, mb = "6.4rem" }: SummaryListProps) => {
    const items = listItems.map(({ field, value }) => <SummaryListItem field={field} value={value} key={field} />);

    const dataTestId = `${title}-fields-wrapper`.toLowerCase().replace(/ /i, '-');
    return (
        <Box mb={mb}>
            <Flex
                justify="space-between"
                borderBottom="0.1rem"
                borderStyle="solid"
                borderColor="neutral.300"
                pb="1.6rem"
            >
                <Heading color="$text-01" size="h3" as="h2">
                    {title}
                </Heading>
                {onEdit && (
                    <Flex
                        align="center"
                        as="button"
                        cursor="pointer"
                        onClick={onEdit}
                        tabIndex={0}
                        aria-label={`Edit ${title}`}
                    >
                        <EditIcon mr="0.8rem" color="$link-01" boxSize="2.4rem" />
                        <Text textStyle="body-03" color="$link-01">
                            Edit
                        </Text>
                    </Flex>
                )}
            </Flex>
            <OrderedList m="0" data-testid={dataTestId}>{items}</OrderedList>
        </Box>
    );
};

export default SummaryList;
