import { ConfirmIcon } from "../../icon";
import { Text } from "@allica/ui-react";
import { HStack } from "@chakra-ui/react";

interface Props {
  bankName: string;
}

export const ValidBankConfirmationMessage = ({ bankName }: Props) => {
  return (
    <HStack mt="0.5rem">
      <ConfirmIcon color="$success-01" boxSize="1.75rem" fill="$ui-02" mr="0.5rem" />
      <Text as="p" textStyle="body-02-regular" color="#424E61">
        {bankName}
      </Text>
    </HStack>
  );
};
