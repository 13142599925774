import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import { Radio, RadioGroup } from "@allica/ui-react";
import { FormGroup } from "src/components/input-set/FormGroup";
import {
  CountryOfOperationsFormValues,
  CountryOfOperationsProps,
} from "./CountryOfOperations.types";
import AddCountryOfOperations from "./AddCountryOfOperations";

const CountriesOfOperations = ({ ...styleProps }: CountryOfOperationsProps) => {
  const { control, getValues, setError, clearErrors } =
    useFormContext<CountryOfOperationsFormValues>();
  const { fields, append, remove } = useFieldArray({ name: "countries", control });
  const [changedCountry, setChangeCountry] = useState("");

  const [countryStatus, setCountryStatus] = useState("0");

  useEffect(() => {
    const countriesLength = getValues("countries")?.length;
    const status = countriesLength > 0 ? "1" : "0";
    setCountryStatus(status);
  }, []);

  useEffect(() => {
    if (countryStatus === "1" && fields.length < 1) {
      append({ value: "" });
    }
  }, [countryStatus]);

  useEffect(() => {
    if (fields.length < 1) {
      setCountryStatus("0");
    } else {
      setCountryStatus("1");
    }
  }, [fields]);

  const handleAddClick = (newCountryNumber: number) => {
    clearErrors(`countries.${newCountryNumber + 1}`);
    const currentCountryCode = getValues(`countries.${newCountryNumber}.value`);

    if (currentCountryCode) {
      append({ value: "" });
    } else {
      setError(`countries.${newCountryNumber}.value`, {
        message: "Please select a country from the list",
      });
    }
  };

  const handleDeleteClick = (newCountryNumber: number) => {
    remove(newCountryNumber);
    setChangeCountry("");
  };

  const handleCountryChange = (e: Event) => {
    const { value } = e?.target as HTMLInputElement;
    setChangeCountry(value);
  };

  const handleStatusChange = (value: string) => {
    if (value === "0") {
      remove();
    }
    setCountryStatus(value);
  };

  return (
    <Box {...styleProps}>
      <FormGroup
        label="Countries of operation"
        isRequired
        info={"Do you have any customers, suppliers or officers outside of the UK?"}
      >
        <RadioGroup onChange={handleStatusChange} value={countryStatus}>
          <Radio value="0">No</Radio>
          <Radio value="1">Yes</Radio>
        </RadioGroup>
      </FormGroup>

      {countryStatus === "1" &&
        fields?.map((item, index) => (
          <AddCountryOfOperations
            key={item.id}
            countryNumber={index}
            handleAddClick={handleAddClick}
            handleDeleteClick={handleDeleteClick}
            handleCountryChange={handleCountryChange}
            changedCountry={changedCountry}
            allCountries={fields}
          />
        ))}
    </Box>
  );
};
export default CountriesOfOperations;
