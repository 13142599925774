import { Step, Steps } from "@allica/ui-react";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { ConfirmationOfPayeeResponse } from "src/core/service/confirmation-of-payee-api/ConfirmationOfPayeeApi.types";
import { ProductCatalogItem } from "src/core/service/products-api/ProductsApi.types";
import { ApplicantSection } from "src/shared/applicant/Applicant.types";
import { CompanySectionType } from "src/shared/company/Company.types";

export enum Stage {
  SIGN_UP = "sign-up",
  VERIFY = "verify",
  BUSINESS = "business",
  APPLICANT = "applicant",
  // SHAREHOLDERS = "shareholders",
  ACCOUNT = "account",
  DEPOSIT = "deposit",
  DOCUMENT = "document",
  SUMMARY = "summary",
}

export enum SubmittedStages {
  COMPLETED = "COMPLETED",
  READY_TO_REVIEW = "READY_TO_REVIEW",
  SPLITTING = "SPLITTING",
  RISK_CHECK_IN_PROGRESS = "RISK_CHECK_IN_PROGRESS",
  PROCESSING_ERROR = "PROCESSING_ERROR",
}

export enum ApplicationStatuses {
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  REVIEW = "REVIEW",
  FAIL = "FAIL",
}

type ApplicationSteps = keyof typeof Stage;
export type CurrentApplicationStage = ApplicationSteps | keyof typeof SubmittedStages;
export type ApplicationStatusType = keyof typeof ApplicationStatuses;

export type AlertStage =
  | Stage.APPLICANT
  // |  Stage.SHAREHOLDERS
  | Stage.ACCOUNT;

export type UpdateStepperConfig = (
  stages: { stage: Stage; value: Partial<Step> }[],
  disableCompleteOverride?: boolean,
) => void;

export interface ApplicationSections {
  signUpSection: {
    emailAddress: string;
    phoneNumber: string;
    productReference: string;
    productIssueId: string;
    password?: string;
    domain: "BUSINESS";
  };
  verifySection?: {
    noOfSecurityCodeSent: number;
  };
  companySection: CompanySectionType;
  applicantSection: ApplicantSection | null;
  depositSection: {
    depositAmount: string;
    depositDuration: string;
  };
  accountSection: {
    name: string;
    sortCode: string;
    accountNumber: string;
    copResponse: ConfirmationOfPayeeResponse;
  };
  documentSection: {
    terms: boolean;
    email?: boolean;
    sms?: boolean;
    phone?: boolean;
    mail?: boolean;
  };
  summarySection: {
    detailsVerified: boolean;
  };
  outcome: {
    success: boolean;
  };
}

export interface BusinessSavingApiType extends ApplicationSections {
  currentApplicationStage: CurrentApplicationStage | null;
  cobApplicationId: string;
  braInterest?: boolean | null;
}

export interface BusinessSavingDataTypes {
  applicationID: string;
  currentApplicationStage: CurrentApplicationStage | null;
  cobApplicationId: string;
  businessApplicationSections: ApplicationSections;
  braInterest?: boolean | null;
}

export interface BusinessContextType {
  currentStage: Stage | null;
  setCurrentStage: Dispatch<SetStateAction<Stage | null>>;
  stepperConfig: Steps<Stage>;
  updateStepperConfig: UpdateStepperConfig;
  businessSavingData: BusinessSavingDataTypes;
  setBusinessSavingData: Dispatch<React.SetStateAction<BusinessSavingDataTypes>>;
  showGenericError: boolean;
  selectedProductInfo: ProductCatalogItem;
  setShowGenericError: Dispatch<React.SetStateAction<boolean>>;
  alertStages: AlertStage[];
  setAlertStages: Dispatch<React.SetStateAction<AlertStage[]>>;
  isBusinessSavingDataLoading: boolean;
}

export type BusinessContextProviderType = {
  children?: ReactNode;
  applicationID: string;
  applicationIDStatus: { loading: boolean; success: boolean };
};
