import { Icon, IconProps } from "@chakra-ui/react";

export const LinkIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 4.50003L13.5 3.00003L21.0017 3L21.0016 10.5L19.5016 10.5L19.5016 5.56066L13.7056 11.3566L12.645 10.296L18.441 4.50001L13.5 4.50003ZM4.5 4.5H10.5V6H4.5L4.5 19.5H18V13.5H19.5V19.5C19.5 20.3284 18.8284 21 18 21H4.5C3.67157 21 3 20.3284 3 19.5V6C3 5.17157 3.67157 4.5 4.5 4.5Z"
      fill="#2458F0"
    />
  </Icon>
);
