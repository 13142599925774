import { useEffect, useState } from "react";
import { StepStatus } from "@allica/ui-react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { VerifyUIProps, VerifyFormValues } from "src/shared/verify/Verify.types";
import VerifyUI from "src/shared/verify/VerifyUI";
import { BusinessRewardsDataTypes, Stage } from "../../BusinessRewards.types";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { DepositsAPI } from "src/core/service";
import {
  VerifyErrorFields,
  VerifyResponseType,
} from "src/core/service/deposits-api/DepositsApi.types";
import { otpRelatedErrorCodes } from "../../../../shared/verify/Verify.utils";

const BusinessRewardsVerify = () => {
  const [noOfSecurityCodeSent, setNoOfSecurityCodeSent] = useState<number>(1);

  const {
    businessRewardsData,
    setBusinessRewardsData,
    setCurrentStage,
    setShowGenericError,
    updateStepperConfig,
  } = useBusinessRewardsContext();

  const { phoneNumber, password } =
    businessRewardsData?.businessRewardsApplicationSections?.signUpSection;

  const formMethods = useForm<VerifyFormValues>();

  const { setError } = formMethods;

  const {
    response: saveResponse,
    status: saveReqStatus,
    request: saveData,
    error: saveError,
  } = DepositsAPI<VerifyResponseType, VerifyErrorFields>(
    `applications/${businessRewardsData?.applicationID}/otp`,
  );

  const onSubmit: SubmitHandler<VerifyFormValues> = (values) => {
    setShowGenericError(false);
    const { otp } = values;
    const payload = { password, otp };
    saveData({
      method: "POST",
      body: JSON.stringify(payload),
    });
  };

  const navigateToSignUp = () => {
    setCurrentStage(Stage.SIGN_UP);
  };

  useEffect(() => {
    const savedNoOfSecurityCodeSent =
      businessRewardsData?.businessRewardsApplicationSections?.verifySection?.noOfSecurityCodeSent;

    savedNoOfSecurityCodeSent !== noOfSecurityCodeSent &&
      setNoOfSecurityCodeSent(savedNoOfSecurityCodeSent ?? 1);
  }, [
    businessRewardsData?.businessRewardsApplicationSections?.verifySection?.noOfSecurityCodeSent,
  ]);

  useEffect(() => {
    if (saveReqStatus.success) {
      sessionStorage.setItem("token", saveResponse.accessToken);
      sessionStorage.setItem("refreshToken", saveResponse.refreshToken);
      const encodedApplicationId = window.btoa(businessRewardsData?.applicationID);
      sessionStorage.setItem("applicationID", encodedApplicationId.toString());

      const newSigUpData = { ...businessRewardsData };
      newSigUpData.businessRewardsApplicationSections.signUpSection = {
        ...newSigUpData.businessRewardsApplicationSections.signUpSection,
        password: "",
      };
      setBusinessRewardsData(newSigUpData);

      updateStepperConfig([
        { stage: Stage.SIGN_UP, value: { disabled: true } },
        { stage: Stage.VERIFY, value: { status: StepStatus.COMPLETE, disabled: true } },
        { stage: Stage.BUSINESS, value: { status: StepStatus.INCOMPLETE } },
      ]);

      setCurrentStage(Stage.BUSINESS);
    }
  }, [saveReqStatus.success]);

  useEffect(() => {
    if (saveReqStatus.error) {
      if (saveError.code === "VALIDATION_ERROR") {
        saveError?.errors?.forEach((data) => {
          const { field, reason } = data;
          setError(field, { message: reason });
        });
      } else if (saveError.code && !otpRelatedErrorCodes.includes(saveError.code)) {
        setError("otp", { message: saveError.message });
      } else setShowGenericError(true);
    }
  }, [saveReqStatus.error]);

  const VerifyFormProps: VerifyUIProps<BusinessRewardsDataTypes> = {
    setShowGenericError,
    navigateToSignUp,
    noOfSecurityCodeSent,
    setNoOfSecurityCodeSent,
    phoneNumber,
    onSubmit,
    isSubmitting: saveReqStatus.loading,
    savingData: businessRewardsData,
    setSavingData: setBusinessRewardsData,
  };

  return (
    <FormProvider {...formMethods}>
      <VerifyUI<BusinessRewardsDataTypes> {...VerifyFormProps} />
    </FormProvider>
  );
};

export default BusinessRewardsVerify;
