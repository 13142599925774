import { Dispatch, SetStateAction } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { UseFormSetError } from "react-hook-form";
import { appInsights } from "src/core/app/ApplicationInsights";
import { SignUpErrorFields } from "src/core/service/deposits-api/DepositsApi.types";
import { ErrorResponse } from "src/core/service/Service.types";
import { SignUpFormValues } from "./SignUp.types";

export const handleSignUpError = (
  error: ErrorResponse<SignUpErrorFields>,
  setError: UseFormSetError<SignUpFormValues>,
  openModal: () => void,
  setShowGenericError: Dispatch<SetStateAction<boolean>>,
) => {
  switch (error.code) {
    case "VALIDATION_ERROR":
      error?.errors?.forEach((data) => {
        const { field, reason } = data;
        setError(field, { message: reason }, { shouldFocus: true });
      });
      break;
    case "CJDEPT_210":
    case "CJDEPT_216":
    case "CJDEPT_217":
      openModal();
      appInsights.trackException({
        exception: new Error("email-already-in-use"),
        severityLevel: SeverityLevel.Information,
      });
      break;
    case "CJDEPT_213":
      setError(
        "phoneNumber",
        { message: "Please enter a valid mobile number" },
        { shouldFocus: true },
      );
      break;
    case "CJDEPT_248":
      setError(
        "emailAddress",
        { message: "Please enter a valid email address" },
        { shouldFocus: true },
      );
      break;
    case "PING_007":
      if (error.message?.includes("phone must be a well-formed phone number")) {
        setError(
          "phoneNumber",
          { message: "Please enter a valid mobile number" },
          { shouldFocus: true },
        );
      }
      break;
    default:
      setShowGenericError(true);
  }
};
