import { forwardRef } from "react";
import { Checkbox as ChakraCheckbox, CheckboxProps } from "@chakra-ui/react";
import { TickIcon } from "../icon";

// override the label font size
import style from "./Checkbox.module.css";

// https://github.com/chakra-ui/chakra-ui/issues/7044
const CheckboxIcon = ({
  isChecked,
  isIndeterminate,
  as: Element,
  ...rest
}: {
  isChecked?: boolean;
  isIndeterminate?: boolean;
  as: React.ElementType;
}) => {
  return <Element {...rest} />;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, ...props }, ref) => {
    return (
      <ChakraCheckbox
        ref={ref}
        icon={<CheckboxIcon as={TickIcon} />}
        className={style.checkboxLabel}
        {...props}
      >
        {children}
      </ChakraCheckbox>
    );
  }
);

export default Checkbox;
