import { ConfirmationOfPayeeResponse } from "src/core/service/confirmation-of-payee-api/ConfirmationOfPayeeApi.types";
import { MatchModal, NoMatchModal, PartialMatchModal, UnableToVerifyModal } from "..";

interface ConfirmationOfPayeeModalProps {
  copResponse: ConfirmationOfPayeeResponse;
  accountDetails: { name: string; sortCode: string; accountNumber: string };
  isOpen: boolean;
  continueAction: () => void;
  onClose: () => void;
  continueActionLoading: boolean;
  businessAccount?: boolean;
}

export const ConfirmationOfPayeeModal = ({
  copResponse,
  accountDetails,
  isOpen,
  continueAction,
  continueActionLoading,
  onClose,
  businessAccount,
}: ConfirmationOfPayeeModalProps) => {
  if (copResponse.matched) {
    return (
      <MatchModal
        name={accountDetails.name}
        sortCode={accountDetails.sortCode}
        accountNumber={accountDetails.accountNumber}
        primaryAction={continueAction}
        primaryActionLoading={continueActionLoading}
        onClose={onClose}
        isOpen={isOpen}
      />
    );
  }

  if (!copResponse.errors) return null;

  const unableToVerify = copResponse.errors.some((error) => error.errorType === "UNABLE_TO_VERIFY");
  if (unableToVerify) {
    return (
      <UnableToVerifyModal
        continueAction={continueAction}
        continueActionLoading={continueActionLoading}
        onClose={onClose}
        isOpen={isOpen}
      />
    );
  }

  const partialMatch = copResponse.errors.some((error) => error.errorType === "PARTIAL_MATCH");
  // wrong account type also classed as a partial match
  const wrongAccountType = copResponse.errors.some(
    (error) => error.field === "ACCOUNT_TYPE" && error.errorType === "NO_MATCH",
  );

  if (!partialMatch && !wrongAccountType) {
    return (
      <NoMatchModal
        continueAction={continueAction}
        continueActionLoading={continueActionLoading}
        onClose={onClose}
        isOpen={isOpen}
      />
    );
  }

  const suggestedFix = copResponse.errors.find((error) => !!error.suggestedFix)?.suggestedFix;

  return (
    <PartialMatchModal
      name={accountDetails.name}
      sortCode={accountDetails.sortCode}
      accountNumber={accountDetails.accountNumber}
      primaryAction={onClose}
      secondaryAction={continueAction}
      onClose={onClose}
      isOpen={isOpen}
      suggestedFix={suggestedFix}
      wrongAccountType={wrongAccountType}
      secondaryActionLoading={continueActionLoading}
      businessAccount={businessAccount}
    />
  );
};
