import AppRouter from "../router/Router";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./ApplicationInsights";
import ErrorBoundary from "../error/ErrorBoundary";
import { StoreProvider } from "../store/StoreContext";

function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ErrorBoundary>
        <StoreProvider>
          <AppRouter />
        </StoreProvider>
      </ErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export default App;
