import { getTextStyle, Text } from "@allica/ui-react";
import { Stack } from "@chakra-ui/react";
import { SummaryListItemProps } from "./SummaryListItem.types";

const SummaryListItem = ({ field, value }: SummaryListItemProps) => {
  return (
    <Stack
      as="li"
      spacing={{ base: "0.8rem", md: "2.4rem" }}
      borderBottom="0.1rem"
      borderStyle="solid"
      borderColor="neutral.300"
      p="1.6rem 0"
      direction={{ base: "column", md: "row" }}
    >
      <Text
        as="p"
        sx={getTextStyle("body-03")}
        color="$text-03"
        minW="14rem"
        maxW={{ base: "unset", md: "14rem" }}
      >
        {field}
      </Text>
      <Text as="p" sx={getTextStyle("medium-03")} color="$text-01">
        {value}
      </Text>
    </Stack>
  );
};

export default SummaryListItem;
