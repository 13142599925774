import { Icon, IconProps } from "@chakra-ui/react";

export const WarningIcon = (props: IconProps) => (
  <Icon width="32" height="32" viewBox="0 0 32 32" fill="currentColor" {...props}>
    <circle cx="16" cy="16" r="14" fill="currentColor" />
    <path
      d="M15.5513 8C14.9794 8 14.5239 8.47875 14.5525 9.04994L14.9525 18.0499C14.9791 18.5821 15.4184 19 15.9513 19H16.0488C16.5816 19 17.0209 18.5821 17.0475 18.0499L17.4475 9.04994C17.4761 8.47875 17.0206 8 16.4488 8H15.5513Z"
      fill="white"
    />
    <path
      d="M16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24Z"
      fill="white"
    />
  </Icon>
);
