import { useEffect, useState } from "react";
import { Button, getHeadingStyle, Heading, RadioGroup, Text } from "@allica/ui-react";
import { Card, FormErrorMessage, FormHelperText, Radio, Stack } from "@chakra-ui/react";
import { FormGroup } from "src/components/input-set/FormGroup";
import AddressWrapper from "../address-wrapper/AddressWrapper";
import { BaseAddressOptionType, ManageAddressProps } from "./ManageAddress.types";
import { useFormContext } from "react-hook-form";
import { convertAddressObjToString } from "src/shared/company/Company.utils";
import {
  defaultAddressDetails,
  defaultAddressTypes,
  firstLetterUpperCase,
} from "./manageAddress.utils";
import { getSentenceCase } from "src/core/utils";

const ManageAddress = ({
  addressType,
  addressTypeLabel,
  addressHelperText,
  addressOptions,
  setAddressOptions,
  addressCardLabel = "",
  fieldRootPath = "",
  addressNumber = -1,
  defaultSelectedAddress,
  heapId,
}: ManageAddressProps) => {
  const rootPath = fieldRootPath ? `${fieldRootPath}.` : "";
  const defaultOption: BaseAddressOptionType = {
    addressType: "new",
    text: "add new address",
    helperText: "",
    addressDetails: defaultAddressDetails,
  };
  const [showManualAdd, setShowManualAdd] = useState(false);
  const [addressStatus, setAddressStatus] = useState(`${defaultSelectedAddress || 0}`);
  const [manageAddressOptions, setManageAddressOptions] = useState([...addressOptions]);
  const [isInitialAddressStatusUpdated, setIsInitialAddressStatusUpdated] = useState(false);
  const methods = useFormContext();
  const addressError = (methods.formState.errors?.addresses as any)?.[addressNumber];

  useEffect(() => {
    const currentAddressIndex = addressOptions?.findIndex(
      (opt) => opt?.addressType === addressType,
    );
    currentAddressIndex !== -1 &&
      setAddressStatus(`${currentAddressIndex || defaultSelectedAddress || 0}`);
    setIsInitialAddressStatusUpdated(true);
  }, []);

  useEffect(() => {
    if (isInitialAddressStatusUpdated) {
      const newOptions = [...addressOptions];
      const currentAddressIndex = addressOptions?.findIndex(
        (opt) => opt?.addressType === addressType,
      );
      if (currentAddressIndex !== -1) {
        newOptions.push({
          ...defaultOption,
          addressType: "edit",
          text: `edit ${addressCardLabel} ${addressTypeLabel}`,
        });
      } else {
        newOptions.push({ ...defaultOption });
        if (`${defaultSelectedAddress}` === `${addressOptions.length}`) {
          methods?.setValue(rootPath, {
            ...defaultAddressDetails,
            addressType: addressType,
          });
        }
      }
      if (addressOptions?.[+addressStatus]?.addressDetails?.addressLine1) {
        methods?.setValue(rootPath, {
          ...addressOptions?.[+addressStatus]?.addressDetails,
          addressType: addressType,
        });
      }
      setManageAddressOptions(newOptions);
    }
  }, [addressOptions, isInitialAddressStatusUpdated]);

  const onAddressStatusChange = (e: any) => {
    if (addressError?.message && rootPath) {
      methods?.clearErrors(`${rootPath}`);
    }
    const options = [...addressOptions];
    let selectedAddressDetails = options?.[+e]?.addressDetails || defaultAddressDetails;
    if (manageAddressOptions?.[+e]?.addressType === "new") {
      setShowManualAdd(false);
    } else if (manageAddressOptions?.[+e]?.addressType === "edit") {
      selectedAddressDetails = addressOptions?.find((add) => add?.addressType === addressType)
        ?.addressDetails || { ...defaultAddressDetails, addressType: addressType };
      setShowManualAdd(true);
    }
    methods?.setValue(rootPath, { ...selectedAddressDetails, addressType: addressType });
    setAddressStatus(e);
  };

  const handleSubmitAddress = async () => {
    if (addressError?.message) {
      methods?.clearErrors(`${rootPath}`);
    }
    const result = await methods?.trigger([
      rootPath + "addressLine1",
      rootPath + "addressLine2",
      rootPath + "addressLine3",
      rootPath + "city",
      rootPath + "county",
      rootPath + "postCode",
      rootPath + "countryIsoCode",
    ]);
    if (result) {
      const options = [...addressOptions];
      let idx = addressStatus;
      const { searchAddress, ...addressData } = methods?.getValues(`${rootPath}`);
      const currentAddressType = options?.filter((opt) => opt?.addressType === addressType);
      if (
        currentAddressType?.length &&
        manageAddressOptions[manageAddressOptions?.length - 1]?.addressType === "edit"
      ) {
        for (let i = 0; i < options?.length; i++) {
          const item = options?.[i];
          if (item?.addressType === addressType) {
            idx = `${i}`;
            item.addressDetails = addressData;
            item.helperText = convertAddressObjToString({
              ...addressData,
              addressType: "",
              nodeId: "",
              nodeType: "",
              country: "",
              addressStatus: "",
            });
            break;
          }
        }
      } else {
        options?.push({
          addressType: addressType,
          text: `${addressCardLabel} ${addressTypeLabel}`,
          helperText: convertAddressObjToString({
            ...addressData,
            addressType: "",
            nodeId: "",
            nodeType: "",
            country: "",
            addressStatus: "",
          }),
          addressDetails: addressData,
        });
      }
      setAddressStatus(idx);
      setAddressOptions(options);
    }
  };

  return (
    <>
      <FormGroup
        label={addressTypeLabel}
        css={firstLetterUpperCase}
        isRequired
        isInvalid={!!addressError?.message}
        mb={addressStatus === `${manageAddressOptions?.length - 1}` ? "2.4rem" : "3.2rem"}
      >
        <RadioGroup onChange={onAddressStatusChange} value={addressStatus}>
          <Stack direction="column">
            {manageAddressOptions?.map(
              (address, index) =>
                (address?.helperText ||
                  address?.addressType === "new" ||
                  address?.addressType === "edit") && (
                  <Radio value={`${index}`} key={address?.addressType}>
                    <Text css={firstLetterUpperCase}>
                      {address?.addressType === addressType ||
                      defaultAddressTypes?.includes(address?.addressType)
                        ? address?.text?.toLowerCase()
                        : `use ${address?.text?.toLowerCase()}`}
                    </Text>
                    {address?.helperText && <FormHelperText>{address?.helperText}</FormHelperText>}
                  </Radio>
                ),
            )}
          </Stack>
        </RadioGroup>
        {addressNumber > -1 && addressError?.message && (
          <FormErrorMessage>{addressError?.message}</FormErrorMessage>
        )}
      </FormGroup>
      {addressStatus === `${manageAddressOptions?.length - 1}` && (
        <Card
          variant="outline"
          px="3.2rem"
          mt={"0.2rem"}
          mb={"2.4rem"}
          bgColor="$ui-01"
          paddingBottom={"4rem"}
        >
          <Heading sx={getHeadingStyle("heading-05")} color="$interactive-02" mt="2.4rem" mb="4rem">
            Add {`${addressCardLabel} ${addressTypeLabel}`}
          </Heading>
          <AddressWrapper
            addressTypeLabel={getSentenceCase(addressTypeLabel)}
            addressHelperText={addressHelperText}
            showManualAdd={showManualAdd}
            setShowManualAdd={setShowManualAdd}
            fieldRootPath={fieldRootPath}
            heapId={heapId}
          />

          {showManualAdd && (
            <Button
              w="10rem"
              variant={"secondary"}
              loadingText="Save"
              spinnerPlacement="end"
              float="right"
              padding="2rem 3.2rem"
              onClick={handleSubmitAddress}
              mt="8rem"
            >
              Save
            </Button>
          )}
        </Card>
      )}
    </>
  );
};

export default ManageAddress;
