import { Icon } from "@chakra-ui/react";
import styles from "./RepeatIcon.module.css";
import { RepeatIconProps } from "./RepeatIcon.types";

export const RepeatIcon = ({ spin, ...props }: RepeatIconProps) => (
  <Icon viewBox="0 0 24 24" role="icon" className={spin ? styles.spin : ""} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.14516 10.3888L7.50983 10.3821L7.53673 4.92338L2.07804 4.95028L2.07131 6.31495L5.16309 6.29972C2.19759 9.82055 2.34121 15.0688 5.63568 18.3632C8.10957 20.8371 11.6867 21.5335 14.8025 20.4729L14.3192 19.0529C11.7179 19.9384 8.74661 19.3528 6.69634 17.3026C4.02266 14.6289 3.84111 10.3864 6.15964 7.45087L6.14516 10.3888ZM17.8541 13.6097L16.4894 13.6164L16.4625 19.0751L21.9212 19.0482L21.9279 17.6835L18.8359 17.6988C21.8002 14.178 21.6562 8.93071 18.3622 5.63668C15.8764 3.15083 12.2767 2.45968 9.15022 3.54251L9.64113 4.9599C12.2513 4.05589 15.2414 4.63718 17.3015 6.69734C19.9747 9.37048 20.1567 13.6118 17.8396 16.5473L17.8541 13.6097Z"
      fill="currentColor"
    />
  </Icon>
);
