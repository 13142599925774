export const getCookie = (name: string) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
};

const TRACKING_PARAMETERS = [
  "utm_source",
  "utm_medium",
  "utm_content",
  "utm_campaign",
  "utm_term",
  "gclid",
  "fbclid",
  "_fbc",
  "_fbp",
];

export const getCookiesObject = (cookies: string[]) => {
  return cookies.reduce<Record<string, string>>((prev, curr) => {
    const value = getCookie(curr);
    if (!value) return prev;
    let key = curr;
    if (curr.startsWith("_")) {
      key = curr.slice(1);
    }
    return { ...prev, [key]: value };
  }, {});
};

export const getUtmParameters = () => getCookiesObject(TRACKING_PARAMETERS);
