import { usePersonalContext } from "../../context/PersonalContext";
import DepositRefer from "./deposit-refer/DepositRefer";
import DepositSuccessOld from "./deposit-success-old/DepositSuccessOld";
import { DepositSuccess } from "./deposit-success/DepositSuccess";
import { isFeatureActive } from "../../../../components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "../../../../environments/feature.flags";

const Outcome = () => {
  const { personalSavingData } = usePersonalContext();

  const success = personalSavingData?.individualApplicationSections?.outcome?.success;
  const accountDetails = personalSavingData?.individualApplicationSections?.outcome?.accountDetails;

  if (success) {
    if (isFeatureActive(FeatureFlag.NEW_OUTCOME_PAGE) && accountDetails) {
      return <DepositSuccess />;
    }
    return <DepositSuccessOld />;
  }
  return <DepositRefer />;
};

export default Outcome;
