import { Interpolation } from "@emotion/react";

export const getObjectValues = (obj: any, path: string) => {
  if (!path) return "";
  let rv;
  let idx;
  const parts = path.split(".");

  for (rv = obj, idx = 0; rv && idx < parts.length; ++idx) {
    rv = rv[parts[idx]];
  }
  return rv;
};

export const firstLetterUpperCase: Interpolation<{}> = {
  "&:first-letter": {
    textTransform: "uppercase",
  },
};

export const defaultAddressTypes = ["new", "edit"];
export const defaultAddressDetails = {
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  county: "",
  postCode: "",
  countryIsoCode: "GB",
};
