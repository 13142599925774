import { Box } from "@chakra-ui/react";
import environment from "../../environments/environment";
import Header from "src/components/header/Header";

const Version = () => (
  <>
    <Header />
    <Box textAlign="center" marginTop="180" as="p">
      {environment.name.toLocaleUpperCase()} <b>v{process.env.REACT_APP_VERSION}</b>
    </Box>
  </>
);

export default Version;
