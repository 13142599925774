import { useState, useEffect, createContext, useContext, ReactNode } from "react";
import { DepositsAPI, DepositsApiType, ProductsAPI } from "../service";
import { ProductCatalogItem } from "../service/products-api/ProductsApi.types";
import { StoreContextType } from "./reference/reference.types";
import { referenceDefaultValues, StoreDefaultValues } from "./reference/storeDefault";
import { useLocation } from "react-router-dom";

const StoreContext = createContext<StoreContextType>(StoreDefaultValues);

const StoreProvider = ({ children }: { children: ReactNode }) => {
  const [referenceData, setReferenceData] = useState(referenceDefaultValues);
  const [referenceDataStatus, setReferenceDataStatus] = useState({ success: false, error: false });

  const {
    response: refData,
    status: getRefDataStatus,
    request: getReferenceData,
    error,
  } = DepositsAPI<DepositsApiType.ScvReferenceData>(
    "reference-data?type=COUNTRIES,INDIVIDUAL_TITLE,NATIONALITY,PHONE_NUMBER_PREFIX,IMPORTANT_DOCUMENTS,BUSINESS_NATURE,SIC_CODES,BUSINESS_INDIVIDUAL_RELATION_TYPE",
  );

  const {
    response: productList,
    status: getProductListStatus,
    request: getProductList,
    error: getProductListError,
  } = ProductsAPI<ProductCatalogItem[]>("catalog/onsale");

  const location = useLocation();

  useEffect(() => {
    if (getRefDataStatus.success && getProductListStatus.success) {
      const referenceData = { ...refData, products: productList };
      setReferenceDataStatus({ ...referenceDataStatus, success: true });
      setReferenceData(referenceData);
      sessionStorage.setItem("reference", JSON.stringify(referenceData));
    } else if (getRefDataStatus.error || getProductListStatus.error) {
      // To view app version in direct access url's, we need to fix below ticket
      // https://eldonhouse.atlassian.net/browse/CJ-6465
      // Also added the path check to view version if api fails
      // https://abl-prep-retail-deposits-blue.non-prod-swa.allica.co.uk/open/version
      if (location.pathname !== "/open/version")
        throw new Error("Unable to load reference data", { cause: error || getProductListError });
    }
  }, [getRefDataStatus, getProductListStatus]);

  useEffect(() => {
    const reference = sessionStorage.getItem("reference");
    const refSessionData = reference ? JSON.parse(reference) : {};

    if (refSessionData.countries && refSessionData.products) {
      setReferenceData(refSessionData);
      setReferenceDataStatus({ ...referenceDataStatus, success: true });
    } else {
      getReferenceData();
      getProductList();
    }
  }, []);

  return (
    <StoreContext.Provider value={{ ...referenceData, storeStatus: referenceDataStatus }}>
      {children}
    </StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export { StoreProvider, useStore };
