import { useEffect } from "react";
import { PageLayout } from "@allica/ui-react";
import { Box, Hide } from "@chakra-ui/react";
import { BrandLayoutProps } from "./BrandLayout.types";

import style from "./BrandLayout.module.css";

const BrandLayout = ({ children }: BrandLayoutProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box as="main" mt="7.2rem" h="calc(100vh - 7.2rem)">
      <Box className={style.brandBg}>
        <Box className={style.bg1} h={{ base: "240px", sm: "200px" }}></Box>
        <Box className={style.bg2}></Box>
        <Box className={style.bg3}></Box>
        <Hide below="md">
          <Box className={style.bg4}></Box>
        </Hide>
      </Box>

      <PageLayout>{children}</PageLayout>
    </Box>
  );
};

export default BrandLayout;
