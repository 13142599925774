import { Icon, IconProps } from "@chakra-ui/react";

export const EditIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7124 5.10572C16.2982 4.51994 17.2479 4.51994 17.8337 5.10572L18.8944 6.16638C19.4801 6.75217 19.4801 7.70192 18.8944 8.2877L8.81809 18.364H5.63611L5.63611 15.182L15.7124 5.10572ZM16.773 6.16638L17.8337 7.22704L16.773 8.2877L15.7124 7.22704L16.773 6.16638ZM7.13611 15.8033L14.6517 8.2877L15.7124 9.34836L8.19677 16.864H7.13611L7.13611 15.8033Z"
      fill="currentColor"
    />
  </Icon>
);
