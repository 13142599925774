import { Icon, IconProps } from "@chakra-ui/react";

export const CopyOutlineIcon = (props: IconProps) => {
  return (
    <Icon
      w="18"
      h="18"
      viewBox="0 0 24 24"
      {...props}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1.5H2V10.5H3.5V12H2C1.17157 12 0.5 11.3284 0.5 10.5V1.5C0.5 0.671573 1.17157 0 2 0H11C11.8284 0 12.5 0.671573 12.5 1.5V3H11V1.5Z"
          fill="#2F60F1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 6C5 5.17157 5.67157 4.5 6.5 4.5H17C17.8284 4.5 18.5 5.17157 18.5 6V16.5C18.5 17.3284 17.8284 18 17 18H6.5C5.67157 18 5 17.3284 5 16.5V6ZM6.5 6H17V16.5H6.5V6Z"
          fill="#2F60F1"
        />
      </svg>
    </Icon>
  );
};
