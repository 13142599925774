import { getTextStyle, Text } from "@allica/ui-react";
import { Flex, Portal, Spinner } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface OverlayProps {
  spinner?: boolean;
  text?: string;
}

export const Overlay = ({ spinner, text, children }: PropsWithChildren<OverlayProps>) => {
  return (
    <Portal>
      <Flex
        position="absolute"
        top="-7.2rem"
        zIndex={9999}
        backgroundColor="$overlay-01"
        h="100vh"
        w="100%"
        opacity="80%"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        {spinner && (
          <Spinner
            height="8.8rem"
            width="8.8rem"
            thickness="0.6rem"
            speed="0.85s"
            color="$ui-02"
            opacity="100%"
            label={text || "loading, please wait"}
          />
        )}
        {text && (
          <Text
            mt="1.6rem"
            sx={getTextStyle("medium-03")}
            color="$text-inverse-01"
            textAlign="center"
            maxWidth="17.6rem"
          >
            {text}
          </Text>
        )}
        {children}
      </Flex>
    </Portal>
  );
};
