import { getTextStyle, Text } from "@allica/ui-react";
import { Box, Flex } from "@chakra-ui/react";
import { ConfirmIcon } from "src/components/icon";
import { Modal } from "src/components/modal/Modal";

interface MatchModalProps {
  name: string;
  sortCode: string;
  accountNumber: string;
  isOpen: boolean;
  onClose: () => void;
  primaryAction: () => void;
  primaryActionLoading: boolean;
}

export const MatchModal = ({
  name,
  sortCode,
  accountNumber,
  isOpen,
  onClose,
  primaryAction,
  primaryActionLoading,
}: MatchModalProps) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      headerTitle="Account details match"
      primaryButtonText="Continue"
      primaryButtonLoading={primaryActionLoading}
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={primaryAction}
      variant="success"
    >
      <Text as="p" mb="4rem" sx={getTextStyle("body-03")} color="$text-02">
        The nominated account details you gave match the details on the account
      </Text>
      <Box>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0.1rem 0"
          borderColor="$ui-01"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            w="21.2rem"
            sx={getTextStyle("body-04-generous")}
            color="$text-03"
          >
            Application for
          </Text>
          <Flex alignItems="center">
            <ConfirmIcon color="$success-01" mr="0.4rem" h="1.6rem" w="1.6rem" fill="$ui-02" />
            <Text as="p" sx={getTextStyle("medium-03")} color="$text-01">
              {name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0 0 0.1rem"
          borderColor="$ui-01"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            w="21.2rem"
            sx={getTextStyle("body-04-generous")}
            color="$text-03"
          >
            Sort code
          </Text>
          <Flex alignItems="center">
            <ConfirmIcon color="$success-01" mr="0.4rem" h="1.6rem" w="1.6rem" fill="$ui-02" />
            <Text as="p" sx={getTextStyle("medium-03")} color="$text-01">
              {sortCode}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", sm: "row" }}
          py="1.6rem"
          borderWidth="0 0 0.1rem"
          borderColor="$ui-01"
        >
          <Text
            mb={{ base: "0.8rem", sm: "0" }}
            as="p"
            w="21.2rem"
            sx={getTextStyle("body-04-generous")}
            color="$text-03"
          >
            Account number
          </Text>
          <Flex alignItems="center">
            <ConfirmIcon color="$success-01" mr="0.4rem" h="1.6rem" w="1.6rem" fill="$ui-02" />
            <Text as="p" sx={getTextStyle("medium-03")} color="$text-01">
              {accountNumber}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
