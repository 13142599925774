import { DocumentFormValues, DocumentSection } from "./Documents.types";

export const replaceUrlParams = (url: string, variables: { [key: string]: string }) => {
  for (var prop in variables) url = url.replace(new RegExp("{" + prop + "}", "g"), variables[prop]);
  return url;
};

export const convertToDocumentSectionStructure = ({ documentTerms, marketingPreferences }: DocumentFormValues) => {
  const payload: DocumentSection = {
    terms: !!documentTerms?.includes("terms"),
    email: false,
    mail: false,
    sms: false,
    phone: false,
  };
  if (marketingPreferences && marketingPreferences?.length > 0) {
    marketingPreferences?.forEach((item: string) => {
      payload[item as keyof DocumentSection] = true;
    });
  }
  return payload;
};
