export const regExDictionary = {
  SORT_CODE_CORRECT_FORMAT: /\d{2}-\d{2}-\d{2}/g,
};

export const Messages = {
  INVALID_SORT_CODE: "Please enter a valid sort code",
  INVALID_SORT_CODE_TWO: "Sort code not valid",
};

export const validateSortCode =
  (isValidSortCode?: boolean) =>
  (value: string): boolean | string => {
    if (isValidSortCode === false && value.length === 8) {
      return Messages.INVALID_SORT_CODE_TWO;
    }

    if (value.length !== 8 || !value.match(regExDictionary.SORT_CODE_CORRECT_FORMAT)) {
      return Messages.INVALID_SORT_CODE;
    }

    return true;
  };
