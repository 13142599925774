import { Button, SimpleSelect } from "@allica/ui-react";
import { Box } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { FormGroup } from "src/components/input-set/FormGroup";
import { emptyErrorDataHeapId } from "src/core/utils";
import { MainApplicantFormValues } from "../Applicant.types";
import { MainApplicantSelectProps } from "./MainApplicantSelect.types";

const MainApplicantSelect = ({
  mainApplicantOptions,
  onChangeHandler,
}: MainApplicantSelectProps) => {
  const {
    setError,
    register,
    formState: { errors },
  } = useFormContext<MainApplicantFormValues>();

  return (
    <>
      <FormGroup
        label="Main applicant"
        mb="4rem"
        isRequired
        info={"Select yourself from the list of directors."}
        error={errors?.mainApplicantSelect?.message}
        data-heapid={emptyErrorDataHeapId(errors?.mainApplicantSelect?.type, "applicant")}
      >
        <SimpleSelect
          placeholder="Please select"
          {...register("mainApplicantSelect", {
            required: "Please select main applicant from the list",
          })}
          onChange={onChangeHandler}
          data-heapid="applicant-selection-search"
        >
          {mainApplicantOptions?.map((item: any, index: number) => (
            <option key={index} value={index}>
              {item?.firstName} {item?.lastName}
            </option>
          ))}
        </SimpleSelect>
      </FormGroup>
      <Box>
        <Button
          onClick={() =>
            setError("mainApplicantSelect", {
              message:
                "If you are not listed on companies House, then you are not eligible to apply for a product at this time",
            })
          }
          variant="text"
          data-heapid="applicant-notlisted-error"
        >
          Can’t see yourself on the list?
        </Button>
      </Box>
    </>
  );
};
export default MainApplicantSelect;
