import { Button, ChevronRightIcon, Heading, StepStatus, Text } from "@allica/ui-react";
import { Box, FormControl, FormErrorMessage } from "@chakra-ui/react";
import SummaryList from "src/components/summary-list/SummaryList";
import Checkbox from "src/components/checkbox/Checkbox";
import { dateFormatReverse, formatCurrency, getAndJoinDescriptions } from "src/core/utils";
import { useEffect, useState } from "react";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { Stage } from "../../BusinessRewards.types";
import { useStore } from "src/core/store/StoreContext";
import { getAddress, getSicCodes } from "src/shared/application-summary/ApplicationSummary.utils";
import ErrorBanner from "src/components/connection-error/ErrorBanner";
import { Overlay } from "src/components/overlay/Overlay";
import { getOptionDescriptionsByIds } from "../account-activity/BusinessRewardsAccountActivity.utils";
import { DepositsAPI } from "src/core/service";
import {
  ApplicationStatusResponse,
  StageOptions,
} from "src/core/service/deposits-api/DepositsApi.types";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { appInsights } from "src/core/app/ApplicationInsights";

const isPhaseTwo = isFeatureActive(FeatureFlag.BRA_PHASE_TWO);

export const BusinessRewardsApplicationSummary = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkedDetails, setCheckedDetails] = useState<boolean>(false);
  const [checkBoxError, setCheckboxError] = useState<boolean>(false);
  const referenceData = useStore();

  const {
    setCurrentStage,
    updateStepperConfig,
    setBusinessRewardsData,
    businessRewardsData,
    alertStages,
    setShowGenericError,
  } = useBusinessRewardsContext();

  const {
    applicationID,
    questions,
    businessRewardsApplicationSections: {
      signUpSection,
      companySection,
      applicantSection,
      depositSection,
      accountSection,
    },
  } = businessRewardsData;

  const {
    status: summaryStatus,
    request: submitSummary,
    response: summaryResponse,
    error: summaryError,
  } = DepositsAPI<ApplicationStatusResponse>(`applications/businesses/${applicationID}/summary`);

  const {
    status: statusInfoStatus,
    response: statusInfoResponse,
    request: statusInfoRequest,
  } = DepositsAPI<ApplicationStatusResponse>(`applications/${applicationID}/status-info`);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!checkedDetails || !!alertStages.length) {
      setCheckboxError(true);
    } else {
      setShowGenericError(false);
      submitSummary({
        method: "PATCH",
        body: JSON.stringify({ detailsVerified: true }),
      });
      setIsLoading(true);
    }
  };

  const showError = () => {
    setIsLoading(false);
    setShowGenericError(true);
    appInsights.trackEvent({ name: `submit-business-rewards-account-application-failed` });
  };

  const setRefer = () => {
    const newData = { ...businessRewardsData };
    newData.businessRewardsApplicationSections.summarySection = { detailsVerified: true };
    newData.businessRewardsApplicationSections.outcome = { success: false };
    newData.cobApplicationId =
      summaryResponse.cobApplicationId || statusInfoResponse.cobApplicationId;
    setBusinessRewardsData(newData);

    appInsights.trackEvent({ name: `business-rewards-account-outcome-refer` });
    appInsights.trackEvent({ name: `business-rewards-account-completed` });
    updateStepperConfig([{ stage: Stage.SUMMARY, value: { status: StepStatus.COMPLETE } }]);
  };

  useEffect(() => {
    // on success, no matter what response - the application is referred (mvp)
    if (summaryStatus.success) setRefer();
    if (summaryStatus.error) {
      // on gateway timeout check the application status
      if (summaryError.status === 504) {
        statusInfoRequest();
      } else {
        showError();
      }
    }
  }, [summaryStatus]);

  useEffect(() => {
    if (statusInfoStatus.success) {
      if (summaryResponse.cobApplicationId) {
        appInsights.trackEvent({
          name: `bra-cob-application-id`,
          properties: { cobID: summaryResponse.cobApplicationId },
        });
      }

      if (statusInfoResponse.applicationStage === StageOptions.SUMMARY) {
        // display an error and allow the user to continue making modifications to the application
        showError();
      } else {
        // refer for any other applicationStage
        setRefer();
      }
    }

    if (statusInfoStatus.error) showError();
  }, [statusInfoStatus]);

  return (
    <>
      {isLoading && <Overlay text="Submitting application, please wait" spinner />}
      <Heading color="$text-01" size="h1" as="h1" mb="1.6rem" mt="6.4rem">
        Application summary
      </Heading>
      <Text color="$text-03" textStyle="body-03" mb="6.4rem">
        Before submitting your application, please make sure all the details you have supplied are
        correct.
      </Text>
      <SummaryList
        title="Account"
        listItems={[{ field: "Account", value: "Business Rewards Account" }]}
      />
      <SummaryList
        title="Sign Up"
        listItems={[
          { field: "Email", value: signUpSection.emailAddress },
          { field: "Mobile number", value: signUpSection.phoneNumber },
        ]}
      />
      <SummaryList
        mb="8rem"
        onEdit={() => setCurrentStage(Stage.BUSINESS)}
        title="Business Details"
        listItems={[
          { field: "Company name", value: companySection.businessName },
          { field: "Company No.", value: companySection.companyHouseNumber },
          { field: "Incorporated on", value: dateFormatReverse(companySection.businessStartDate) },
          {
            field: "Tax Status",
            value: getAndJoinDescriptions(
              companySection.taxIdentifications.map((i) => i.taxCountryCode),
              referenceData.countries,
            ),
          },
          {
            field: "Registered office address",
            value: getAddress("REGISTERED_OFFICE_ADDRESS", companySection.addresses),
          },
          {
            field: "Trading address",
            value: getAddress("TRADING_ADDRESS", companySection.addresses),
          },
          {
            field: "Correspondence address",
            value: getAddress("CORRESPONDENCE_ADDRESS", companySection.addresses),
          },
          {
            field: "Nature of business (SIC)",
            value: getSicCodes(companySection.businessSicCodes, referenceData.sicCodes),
          },
          { field: "Business description", value: companySection.businessExplanation },
          {
            field: "Countries of operation",
            value: getAndJoinDescriptions(
              companySection.internationalTradingCountryIsoCodes,
              referenceData.countries,
            ),
          },
          {
            field: "Estimated annual turnover",
            value: formatCurrency(companySection.expectedAnnualTurnover),
          },
          { field: "Number of employees", value: companySection.numberOfEmployees },
        ]}
      />
      {alertStages.find((alertStage) => alertStage === Stage.APPLICANT) && (
        <Box mb="3.2rem">
          <ErrorBanner
            title="Applicant page requires new information."
            titleCallback={{
              label: "Go to Applicant page",
              method: () => setCurrentStage(Stage.APPLICANT),
            }}
          />
        </Box>
      )}
      <SummaryList
        mb="8rem"
        onEdit={() => setCurrentStage(Stage.APPLICANT)}
        title="Main Applicant"
        listItems={[
          { field: "First name", value: applicantSection?.firstName ?? "" },
          { field: "Last name", value: applicantSection?.lastName ?? "" },
          {
            field: "Role",
            value: applicantSection?.businessIndividualRelations
              ? getAndJoinDescriptions(
                  applicantSection.businessIndividualRelations.map((i) => i.type),
                  referenceData.businessIndividualRelationTypes,
                )
              : "",
          },
          {
            field: "Date of birth",
            value: applicantSection?.dateOfBirthSCV
              ? dateFormatReverse(applicantSection.dateOfBirthSCV)
              : "",
          },
          {
            field: "Country of birth",
            value: applicantSection?.countryOfBirth
              ? getAndJoinDescriptions([applicantSection.countryOfBirth], referenceData.countries)
              : "",
          },
          {
            field: "Nationality",
            value: applicantSection?.nationalities
              ? getAndJoinDescriptions(
                  applicantSection.nationalities as string[],
                  referenceData.nationality,
                )
              : "",
          },
          {
            field: "Shareholding",
            value: applicantSection?.shareHoldingPercentage
              ? `${applicantSection.shareHoldingPercentage}%`
              : "",
          },
          {
            field: "Tax Status",
            value: applicantSection?.taxIdentifications
              ? getAndJoinDescriptions(
                  applicantSection.taxIdentifications.map((i) => i.taxCountryCode),
                  referenceData.countries,
                )
              : "",
          },
          {
            field: "Home address",
            value: applicantSection?.addresses
              ? getAddress("HOME_ADDRESS", applicantSection.addresses)
              : "",
          },
        ]}
      />
      {isPhaseTwo && (
        <>
          <SummaryList
            mb="8rem"
            onEdit={() => setCurrentStage(Stage.DEPOSIT)}
            title="Deposit Amount"
            listItems={[
              { field: "Deposit amount", value: formatCurrency(depositSection!.depositAmount) },
            ]}
          />
          <SummaryList
            mb="8rem"
            onEdit={() => setCurrentStage(Stage.ACCOUNT)}
            title="Account Activity"
            listItems={[
              {
                field: "Purpose of account",
                value: getOptionDescriptionsByIds({
                  optionIds: accountSection!.purposeOfAccount,
                  question: questions!.PURPOSE_OF_ACCOUNT,
                }),
              },
              {
                field: "Monthly payments in",
                value: getOptionDescriptionsByIds({
                  optionIds: [accountSection!.monthlyPaymentsIn],
                  question: questions!.MONTHLY_PAYMENTS_IN,
                }),
              },
              {
                field: "Source of funds",
                value: getOptionDescriptionsByIds({
                  optionIds: accountSection!.sourceOfFunds,
                  question: questions!.SOURCE_OF_FUNDS,
                }),
              },
            ]}
          />
        </>
      )}
      <form onSubmit={handleSubmit}>
        <FormControl isInvalid={checkBoxError}>
          <Checkbox
            checked={checkedDetails}
            onChange={() => {
              setCheckboxError(false);
              setCheckedDetails(!checkedDetails);
            }}
          >
            I have checked my details and can confirm they are correct.
          </Checkbox>
          <FormErrorMessage>Please check and confirm your details</FormErrorMessage>
        </FormControl>
        <Button
          mt="8rem"
          isLoading={isLoading}
          loadingText="Submit application"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon />}
        >
          Submit application
        </Button>
      </form>
    </>
  );
};
