import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function useHideRecaptchaBadge(hideRecaptcha: boolean) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const grecaptchaBadge = document.querySelector(".grecaptcha-badge");

    if (hideRecaptcha && grecaptchaBadge && executeRecaptcha) {
      grecaptchaBadge.setAttribute("style", "visibility: hidden");
    }
  }, [executeRecaptcha, hideRecaptcha]);
}
