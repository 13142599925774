import { ErrorIcon, getTextStyle, Input, Text } from "@allica/ui-react";
import { InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";

import { forwardRef, useState } from "react";
import styles from "./PasswordInput.module.css";

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  ({ w, isInvalid, ...rest }, ref) => {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    return (
      <InputGroup className={styles.passwordInput} w={w ?? "100%"}>
        <Input
          data-testid="password-input"
          type={show ? "text" : "password"}
          ref={ref}
          pr={isInvalid ? "11.3rem" : "7.3rem"}
          bg="$ui-02"
          {...rest}
        />
        <InputRightElement color="$text-01" w="fit-content" sx={getTextStyle("body-03")}>
          {rest.value && (
            <Text
              onClick={handleClick}
              as="button"
              type="button"
              color="$link-01"
              w="4.9rem"
              mr="1.2rem"
            >
              {show ? "Hide" : "Show"}
            </Text>
          )}
          {isInvalid && (
            <ErrorIcon data-testid="passwordInputErrorIcon" color="$danger-01" mr="1.6rem" />
          )}
        </InputRightElement>
      </InputGroup>
    );
  }
);
