import { FeatureFlagType } from "../types/feature.types";

export enum FeatureFlag {
  EXAMPLE = "Example",
  DOCUMENT_SKIP_LINKS = "DocumentSkipLinks",
  BRA = "BRA",
  BRA_PHASE_TWO = "BRA_PHASE_TWO",
  GTM = "GTM",
  NEW_OUTCOME_PAGE = "NEW_OUTCOME_PAGE",
  OTHER_PARTIES = "OTHER_PARTIES",
  SHOW_PERSONAL_SAVINGS_INTEREST_RATE = "SHOW_PERSONAL_SAVINGS_INTEREST_RATE",
  SHOW_BRA_INTEREST_RATE = "SHOW_BRA_INTEREST_RATE",
}

const features: FeatureFlagType = {
  [FeatureFlag.EXAMPLE]: {
    dev: true,
    prep: true,
    prod: true,
    story: "CJ-1234",
  },
  [FeatureFlag.DOCUMENT_SKIP_LINKS]: {
    dev: true,
    prep: true,
    prod: false,
    story: "CJ-2654",
  },
  [FeatureFlag.BRA]: {
    dev: true,
    prep: true,
    prod: true,
    story: "CJ-5798",
  },
  [FeatureFlag.BRA_PHASE_TWO]: {
    dev: true,
    prep: true,
    prod: true,
    story: "CJ-5853",
  },
  [FeatureFlag.GTM]: {
    dev: true,
    prep: true,
    prod: true,
    story: "PT-5843",
  },
  [FeatureFlag.NEW_OUTCOME_PAGE]: {
    dev: true,
    prep: true,
    prod: false,
    story: "PT-8009",
  },
  [FeatureFlag.OTHER_PARTIES]: {
    dev: true,
    prep: true,
    prod: false,
    story: "CJ-6521",
  },
  [FeatureFlag.SHOW_BRA_INTEREST_RATE]: {
    dev: false,
    prep: false,
    prod: false,
    story: "CJ-7006",
  },
  [FeatureFlag.SHOW_PERSONAL_SAVINGS_INTEREST_RATE]: {
    dev: false,
    prep: false,
    prod: false,
    story: "CJ-7006",
  },
};

export default features;
