import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { Overlay } from "src/components/overlay/Overlay";

import { PersonalSavings, Resume, Version } from "../../pages";
import PersonalSavingsRoutes from "src/pages/personal-savings/router/PersonalSavingsRoutes";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import BusinessRewardsRoutes from "src/pages/business-rewards/router/BusinessRewardsRoutes";

const BusinessSavings = lazy(() => import("src/pages/business-savings/BusinessSavings"));
const BusinessRewards = lazy(() => import("src/pages/business-rewards/BusinessRewards"));

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/personal/savings/:product" element={<PersonalSavings />}>
        {PersonalSavingsRoutes}
      </Route>

      <Route
        path="/business/savings/:product/:stage?"
        element={
          <>
            <Suspense fallback={<Overlay spinner />}>
              <BusinessSavings />
            </Suspense>
          </>
        }
      />

      {isFeatureActive(FeatureFlag.BRA) && (
        <Route
          path="/business/current-account"
          element={
            <Suspense fallback={<Overlay spinner />}>
              <BusinessRewards />
            </Suspense>
          }
        >
          {BusinessRewardsRoutes}
        </Route>
      )}
      <Route path="/version" element={<Version />} />
      <Route path="*" element={<Resume />} />
    </Routes>
  );
};

export default AppRouter;
