import { scvRefDefault } from "src/core/service/deposits-api/DepositsApi.types";
import { concatenateAddress } from "src/core/utils";
import { BusinessAddressType } from "src/shared/company/Company.types";

export const getAddress = (
  addressType: string,
  addresses: BusinessAddressType[] | Record<string, any>[],
): string => {
  const address = addresses.find((address) => address.addressType === addressType);

  if (address) return concatenateAddress(address as BusinessAddressType);
  return "";
};

export const getSicCodes = (
  sicCodes: (number | string)[] | string | number,
  sicCodesRef: scvRefDefault[],
): string => {
  let sicCodeString = "";

  if (Array.isArray(sicCodes)) {
    sicCodes.forEach((sicCode) => {
      const data = sicCodesRef.find((code) => code.name === `${sicCode}`);
      if (data) sicCodeString += `${data?.name} - ${data?.description} \n`;
    });
  } else {
    const data = sicCodesRef.find((code) => code.name === `${sicCodes}`);
    if (data) sicCodeString += `${data?.name} - ${data?.description} \n`;
  }

  return sicCodeString;
};
