import { Steps, StepStatus } from "@allica/ui-react";
import { Stage, StageMVP } from "../BusinessRewards.types";

export const businessRewardsCompletedStepperConfigMVP: Steps<StageMVP> = {
  [Stage.SIGN_UP]: {
    label: "Sign Up",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.VERIFY]: {
    label: "Verify",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.BUSINESS]: {
    label: "Business",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.APPLICANT]: {
    label: "Applicant",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.SUMMARY]: {
    label: "Summary",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
};

export const businessRewardsCompletedStepperConfig: Steps<Stage> = {
  [Stage.SIGN_UP]: {
    label: "Sign Up",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.VERIFY]: {
    label: "Verify",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.BUSINESS]: {
    label: "Business",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.APPLICANT]: {
    label: "Applicant",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.PARTIES]: {
    label: "Other parties",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.DEPOSIT]: {
    label: "Deposit",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.ACCOUNT]: {
    label: "Account",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.DOCUMENT]: {
    label: "Document",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
  [Stage.SUMMARY]: {
    label: "Summary",
    disabled: true,
    status: StepStatus.COMPLETE,
  },
};
