import {
  ApplicationInsights,
  ITelemetryItem,
  RemoteDependencyData,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import environment from "src/environments/environment";
import { getSessionID } from "../service/Service.utils";
//Ref: https://github.com/microsoft/applicationinsights-react-js

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: false,
    autoTrackPageVisitTime: false,
    ...environment.appInsights.config,
  },
});

const loadAppInsights = window.location.hostname !== "localhost" && environment.appInsights.enabled;

const MASKED_PATTERN = "removedFromLogs";
enum PrivateUrl {
  COMPANY_NAME = "/companysearch?companyName=",
  ADDRESS_SEARCH = "/addresses?text=",
  ADDRESS_DETAIL = "/addresses/",
}
const blockedUrls = [
  "ads.linkedin.com",
  "clarity.ms",
  "facebook.com",
  "facebook.net",
  "googletagmanager.com",
  "analytics.google.com",
  "bing.com",
  "snap.licdn.com",
  "heapanalytics.com",
  "127.0.0.1",
];

const customTelemetry = (envelope: ITelemetryItem) => {
  const telemetryItem = envelope?.baseData;
  //Include all tags before any filter
  envelope.tags = envelope.tags || [];
  envelope.tags["ai.cloud.role"] = environment.appInsights.role;
  envelope.tags["ai.cloud.roleInstance"] =
    environment.name + " retail-deposits v" + process.env.REACT_APP_VERSION;
  envelope.tags["ai.session.id"] = getSessionID();

  //if any RemoteDependency contains any blockedUrls it will return and not update log
  if (
    envelope.name === RemoteDependencyData.envelopeType &&
    telemetryItem?.name &&
    new RegExp(blockedUrls.join("|")).test(telemetryItem.name)
  ) {
    return false;
  }
  //remove private user information from logs

  if (telemetryItem?.name) {
    switch (true) {
      case telemetryItem?.name.includes(PrivateUrl.ADDRESS_DETAIL):
        telemetryItem.name =
          telemetryItem.name?.split(PrivateUrl.ADDRESS_DETAIL)?.[0] +
          PrivateUrl.ADDRESS_DETAIL +
          MASKED_PATTERN;
        break;
      case telemetryItem?.name.includes(PrivateUrl.ADDRESS_SEARCH):
        telemetryItem.name =
          telemetryItem.name?.split(PrivateUrl.ADDRESS_SEARCH)?.[0] +
          PrivateUrl.ADDRESS_SEARCH +
          MASKED_PATTERN;
        break;
      case telemetryItem?.name.includes(PrivateUrl.COMPANY_NAME):
        telemetryItem.name =
          telemetryItem.name?.split(PrivateUrl.COMPANY_NAME)?.[0] +
          PrivateUrl.COMPANY_NAME +
          MASKED_PATTERN;
        break;

      default:
        break;
    }
  }
};

if (loadAppInsights) {
  appInsights.loadAppInsights();
  if (!appInsights.context.application.ver) {
    appInsights.trackPageView(); // First page view, Manual tracking
    appInsights.context.application.ver = process.env.REACT_APP_VERSION || "";
  }
  appInsights.addTelemetryInitializer(customTelemetry);
}

export { reactPlugin, appInsights };
