import { Textarea, TextareaProps } from "@chakra-ui/react";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

export const BaseTextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ w, isInvalid, ...rest }, forwardedRef) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    //TODO: find different approach for adjusting height of textarea with content
    useEffect(() => {
      if (textAreaRef?.current) {
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
      }
    }, [textAreaRef?.current?.value]);

    useEffect(() => {
      const handleTextAreaHeight = () => {
        textAreaRef!.current!.style.height = "auto";
        textAreaRef!.current!.style.height = textAreaRef!.current!.scrollHeight + "px";
      };
      if (textAreaRef?.current) {
        textAreaRef?.current?.addEventListener("input", handleTextAreaHeight);
      }
      return () => {
        textAreaRef?.current?.removeEventListener("input", handleTextAreaHeight);
      };
    }, [textAreaRef?.current?.value]);

    useImperativeHandle(forwardedRef, () => textAreaRef?.current as HTMLTextAreaElement);

    return (
      <>
        <Textarea
          ref={textAreaRef}
          w={w ?? "100%"}
          bg="$ui-02"
          borderColor={"$ui-04"}
          isInvalid={isInvalid}
          fontSize={rest?.fontSize || "2rem"}
          resize={rest?.resize || "none"}
          isDisabled={rest?.isDisabled || false}
          overflow="hidden"
          minH="5.6rem"
          padding="1.5rem"
          paddingRight="9rem"
          _disabled={
            rest?.isDisabled
              ? {
                  color: "$text-02",
                  borderColor: "$ui-04",
                  background: "$ui-01",
                  opacity: 1,
                  cursor: "not-allowed",
                  WebkitTextFillColor: "#1A3660",
                }
              : undefined
          }
          {...rest}
        ></Textarea>
      </>
    );
  }
);
