import { Select } from "@chakra-ui/react";
import { FormGroup } from "src/components/input-set/FormGroup";
import { useStore } from "src/core/store/StoreContext";
import { FormDataValues, ManualAddressProps } from "./Address.types";
import { BaseInput } from "src/components/input/default/Input";
import { useFormContext } from "react-hook-form";
import { moveUKToFirstPosition } from "src/core/utils";

function ManualAddress({ addressDetails }: ManualAddressProps) {
  const referenceData = useStore();
  const {
    register,
    formState: { errors },
  } = useFormContext<FormDataValues>();
  const countries = moveUKToFirstPosition(referenceData?.countries);

  return (
    <>
      <FormGroup
        label="Address line 1"
        mb="3.2rem"
        isRequired
        error={errors?.addressLine1?.message}
      >
        <BaseInput
          defaultValue={addressDetails?.addressLine1}
          isInvalid={!!errors?.addressLine1}
          {...register("addressLine1", {
            required: "Please enter a valid address",
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup label="Address line 2" mb="3.2rem" error={errors?.addressLine2?.message}>
        <BaseInput
          defaultValue={addressDetails?.addressLine2}
          isInvalid={!!errors?.addressLine2}
          {...register("addressLine2", {
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup
        label="Address line 3"
        mb="3.2rem"
        error={!!errors?.city ? errors?.addressLine3?.message : ""}
      >
        <BaseInput
          defaultValue={addressDetails?.addressLine3}
          isInvalid={!!errors?.addressLine3}
          {...register("addressLine3", {
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup label="Town/city" mb="3.2rem" isRequired error={errors?.city?.message}>
        <BaseInput
          defaultValue={addressDetails?.city}
          w="24.5rem"
          isInvalid={!!errors?.city}
          {...register("city", {
            required: "Please enter a valid town or city",
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup label="County" mb="3.2rem" isRequired error={errors?.county?.message}>
        <BaseInput
          defaultValue={addressDetails?.county}
          isInvalid={!!errors?.county}
          w="24.5rem"
          {...register("county", {
            required: "Please enter a valid County",
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup label="Postcode" mb="3.2rem" isRequired error={errors?.postCode?.message}>
        <BaseInput
          w="24.5rem"
          defaultValue={addressDetails?.postCode}
          isInvalid={!!errors?.postCode}
          {...register("postCode", {
            required: "Please enter a valid postcode",
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup label="Country" isRequired mb="8rem" error={errors?.countryIsoCode?.message}>
        <Select
          width="24.5rem"
          defaultValue={addressDetails?.countryIsoCode}
          sx={{
            paddingRight: "4rem",
          }}
          {...register("countryIsoCode", {
            required: "country should not be empty",
          })}
        >
          {countries?.map((item) => (
            <option key={item?.name} value={item?.name}>
              {item?.description}
            </option>
          ))}
        </Select>
      </FormGroup>
    </>
  );
}

export default ManualAddress;
