import { Heading, getHeadingStyle } from "@allica/ui-react";
import { FormGroup } from "src/components/input-set/FormGroup";
import { BaseInput } from "src/components/input/default/Input";
import { Select } from "@chakra-ui/react";
import { useStore } from "src/core/store/StoreContext";
import { CurrencyInput } from "src/components/input/currency/CurrencyInput";
import { BaseTextArea } from "src/components/text-area/TextArea";
import { Controller, useFormContext } from "react-hook-form";
import { CompanyFormValues } from "../Company.types";
import CountriesOfOperation from "./countries-of-operations/CountriesOfOperations";
import {
  businessDescFieldDataHeapId,
  businessNoOfEmployeeFieldDataHeapId,
  businessSectorFieldDataHeapId,
  businessTurnoverFieldDataHeapId,
} from "../Company.utils";

const CompanyActivities = () => {
  const referenceData = useStore();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<CompanyFormValues>();

  return (
    <>
      <Heading sx={getHeadingStyle("heading-05")} color="$text-01" mb="1.6rem" mt="8rem">
        Business activity
      </Heading>

      <FormGroup
        label="Nature of business (SIC)"
        mb="3.2rem"
        error={errors?.businessSicCodes?.message}
        isRequired
      >
        <BaseTextArea
          resize={"none"}
          isDisabled
          isInvalid={!!errors?.businessSicCodes}
          {...register("businessSicCodes")}
        />
      </FormGroup>
      <FormGroup
        label="Business sector"
        mb="3.2rem"
        error={errors?.businessNature?.message}
        data-heapid={businessSectorFieldDataHeapId(errors?.businessNature?.type)}
        isRequired
      >
        <Select
          placeholder="Please select"
          data-heapid="sector-business-select"
          {...register("businessNature", {
            required: "Please select your Business sector",
          })}
        >
          {referenceData?.businessNatures?.map((item) => (
            <option key={item?.name} value={item?.name}>
              {item?.description}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FormGroup
        label="Business description"
        info="Tell us what you do and paste in your website and/or social links."
        mb="3.2rem"
        error={errors?.businessExplanation?.message}
        data-heapid={businessDescFieldDataHeapId(errors?.businessExplanation?.type)}
        isRequired
      >
        <BaseTextArea
          resize={"none"}
          data-heapid="description-business-enter"
          {...register("businessExplanation", {
            required: "Please describe your business",
            setValueAs: (value) => value?.trim(), //TODO: add more conditions for white spaces
            maxLength: {
              value: 1000,
              message: "Too many characters, please reduce the amount of detail",
            },
            minLength: {
              value: 25,
              message:
                "Please provide more detail on what your business does, perhaps describe your most recent deal - e.g. Customer, supplier, transaction type",
            },
          })}
        />
      </FormGroup>

      <CountriesOfOperation />

      <FormGroup
        label="Estimated annual turnover"
        mb="3.2rem"
        mt="3.2rem"
        isRequired
        error={errors?.expectedAnnualTurnover?.message}
        data-heapid={businessTurnoverFieldDataHeapId(errors?.expectedAnnualTurnover?.type)}
      >
        <Controller
          control={control}
          name="expectedAnnualTurnover"
          rules={{
            required: "Please input your estimated annual turnover, it does not need to be exact",
            min: { value: 1, message: "Must be between 1 and 9999999999" },
            max: { value: 9999999999, message: "Must be between 1 and 9999999999" },
          }}
          render={({ field }) => (
            <CurrencyInput
              asInteger
              w="31.2rem"
              isInvalid={!!errors?.expectedAnnualTurnover}
              data-heapid="turnover-annual-enter"
              {...field}
            />
          )}
        />
      </FormGroup>

      <FormGroup
        label="Number of employees"
        mb="8rem"
        error={errors?.numberOfEmployees?.message}
        data-heapid={businessNoOfEmployeeFieldDataHeapId(errors?.numberOfEmployees?.type)}
        isRequired
      >
        <BaseInput
          isInvalid={!!errors?.numberOfEmployees}
          inputMode="numeric"
          type="number"
          w="23.7rem"
          data-heapid="employees-number-enter"
          {...register("numberOfEmployees", {
            required: "Please input your number of employees, including yourself",
            min: {
              value: 1,
              message: "Please input your number of employees, including yourself",
            },
          })}
        />
      </FormGroup>
    </>
  );
};

export default CompanyActivities;
