import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appInsights } from "src/core/app/ApplicationInsights";

import { DepositsAPI } from "src/core/service";
import { UserApplicationsResponseType } from "src/core/service/deposits-api/DepositsApi.types";
import { ProductCatalogItem } from "src/core/service/products-api/ProductsApi.types";
import { useStore } from "src/core/store/StoreContext";
import { getDefaultPath } from "src/core/utils";

const Resume = () => {
  const defaultUrl = "personal/savings/24-month-fixed-term-deposit";
  const referenceData = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const products = referenceData?.products;

  const getCurrentProduct = (productRefID: string, products: ProductCatalogItem[] = []) => {
    const currentProduct = products?.find((item) => item?.productId === productRefID);
    return currentProduct;
  };

  const navigateToProduct = (productReferenceId: string | null) => {
    if (productReferenceId) {
      const currentProduct = getCurrentProduct(productReferenceId, products);
      navigate(currentProduct?.pageUrl || defaultUrl);
    } else {
      navigate("business/current-account");
    }
  };

  const {
    response: userApplicationResponse,
    status: userApplicationStatus,
    request: getUserApplications,
  } = DepositsAPI<UserApplicationsResponseType>(`applications/current-user`);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      getUserApplications();
    } else {
      const path = getDefaultPath(location);
      navigate(path);
    }
  }, []);

  useEffect(() => {
    if (referenceData.storeStatus?.success) {
      if (userApplicationStatus.success) {
        const latestApplicationID = userApplicationResponse?.[0].applicationRef;
        sessionStorage.setItem("applicationID", window.btoa(latestApplicationID));
        const cobApplicationId = userApplicationResponse?.[0].cobApplicationId;
        if (cobApplicationId) {
          appInsights.trackEvent({
            name: `resume-cob-application-id`,
            properties: { cobID: cobApplicationId },
          });
        }

        const productReferenceId = userApplicationResponse?.[0].productReferenceId;
        navigateToProduct(productReferenceId);
      }

      if (userApplicationStatus.error) navigate(defaultUrl);
    }
  }, [userApplicationStatus, referenceData.storeStatus]);

  return <></>;
};

export default Resume;
