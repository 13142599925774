import { snakeToCamelCase, camelToSnakeCase } from "src/core/utils";
import {
  FormValues,
  ParsedBCAConfig,
  Question,
  QuestionName,
} from "./BusinessRewardsAccountActivity.types";

export const ANNUAL_BUSINESS_TURNOVER_ID = "ANNUAL_BUSINESS_TURNOVER";

export const filterQuestions = (questions: Question[]): Question[] =>
  questions.filter((question) => question.id !== ANNUAL_BUSINESS_TURNOVER_ID);

export const parseBcaConfiguration = (questions: Question[]) => {
  const filteredQuestions = filterQuestions(questions);

  return filteredQuestions.reduce<ParsedBCAConfig>(
    (prev, curr) => ({
      ...prev,
      [curr.id]: { options: curr.options, id: curr.id, description: curr.description },
    }),
    {} as ParsedBCAConfig,
  );
};

export const getOptionDescriptionById = (question: Question, optionId: number) => {
  const option = question.options.find((option) => option.id === Number(optionId));
  if (!option) return "";

  return option.name;
};

export const getOptionDescriptionsByIds = ({
  question,
  optionIds,
}: {
  question: Question;
  optionIds: string[];
}) => {
  const options: string[] = [];

  optionIds.forEach((id) => {
    options.push(getOptionDescriptionById(question, Number(id)));
  });

  return options.filter(Boolean).join(", ");
};

export const parseAccountActivityAnswers = (questions: Question[]): FormValues => {
  const filteredQuestions = filterQuestions(questions);

  return filteredQuestions.reduce<FormValues>((prev, curr) => {
    if (!curr.selectedOptions) return { ...prev };

    let answers: string[] | string = curr.selectedOptions.map((number) => String(number));
    if (curr.id === QuestionName.MONTHLY_PAYMENTS_IN) {
      answers = answers.shift()!;
    }
    return {
      ...prev,
      [snakeToCamelCase(curr.id)]: answers,
    };
  }, {} as FormValues);
};

export const generateAccountActivityPayload = (formValues: FormValues) => {
  const ids = Object.keys(formValues) as (keyof FormValues)[];
  const answers = [];

  for (const id of ids) {
    const questionId = camelToSnakeCase(id);
    const stringValues = formValues[id];
    let selectedOptions: number[];

    if (Array.isArray(stringValues)) {
      selectedOptions = stringValues.map((value) => Number(value));
    } else {
      selectedOptions = [Number(stringValues)];
    }
    answers.push({ questionId, selectedOptions });
  }

  return answers;
};
