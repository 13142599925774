import { ConfirmFillIcon, ErrorFillIcon } from "@allica/ui-react";
import { TaskChecklistItemStatus } from "../TaskChecklist.types";

export const TaskChecklistStatusIcon = ({ status }: { status: TaskChecklistItemStatus }) => {
  switch (status) {
    case "complete":
      return (
        <ConfirmFillIcon
          data-testid="confirmComplete"
          color="$success-01"
          fill="$ui-02"
          mr="1.6rem"
        />
      );
    case "error":
      return (
        <ErrorFillIcon data-testid="errorFill" color="$alert-error-02" fill="$ui-02" mr="1.6rem" />
      );
    default:
      return (
        <ConfirmFillIcon
          data-testid="confirmIncomplete"
          color="neutral.300"
          fill="neutral.300"
          mr="1.6rem"
        />
      );
  }
};
