import { ProductCatalogItem } from "../products-api/ProductsApi.types";
import { SignUpFormValues } from "src/shared/sign-up/SignUp.types";
import { VerifyFormValues } from "src/shared/verify/Verify.types";
import { DepositFormValues } from "src/shared/deposit/Deposit.types";

export interface ScvReferenceData {
  businessIndividualRelationTypes: scvRefDefault[];
  businessNatures: scvRefDefault[];
  countries: scvRefDefault[];
  importantDocuments: scvRefDefault[];
  individualTitle: scvRefDefault[];
  nationality: scvRefDefault[];
  phonePrefix: scvRefDefault[];
  productCatalog: scvRefDefault[]; // this is coming back as an empty array
  products: ProductCatalogItem[];
  sicCodes: scvRefDefault[];
}

export interface scvRefDefault {
  name: string;
  description: string;
}

export interface SignUpResponseType {
  applicationRef: string;
}

export type SignUpErrorFields = keyof SignUpFormValues;

export interface VerifyResponseType {
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
}

export type VerifyErrorFields = keyof VerifyFormValues;

export type DepositErrorFields = keyof DepositFormValues;

interface UserApplicationResponseType {
  applicationRef: string;
  applicationStage: string;
  applicationStatus: string;
  cobApplicationId: string | null;
  emailAddress: string;
  phone: string;
  productReferenceId: string | null;
  riskRequestBatchId: string | null;
}

export type UserApplicationsResponseType = UserApplicationResponseType[];

export enum StatusOptions {
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  REVIEW = "REVIEW",
  FAIL = "FAIL",
}

export enum StageOptions {
  SUMMARY = "SUMMARY",
  RISK_CHECK_IN_PROGRESS = "RISK_CHECK_IN_PROGRESS",
  SPLITTING = "SPLITTING",
  COMPLETED = "COMPLETED",
  PROCESSING_ERROR = "PROCESSING_ERROR",
  IN_REVIEW = "IN_REVIEW",
}

export enum ApplicationStatusSource {
  DEPOSIT = "DEPOSIT",
  BCA = "BCA",
}

type Domain = "BUSINESS" | "INDIVIDUAL" | "BRA";

export interface ApplicationStatusResponse {
  applicationStatus: StatusOptions;
  applicationStage: StageOptions;
  domain: Domain;
  cobApplicationId: string;
  accountId?: string;
  source: ApplicationStatusSource;
}

export interface SignUpRequestBody {
  emailAddress: string;
  phoneNumber: string;
  password?: string;
  domain: Domain;
  captchaToken: string;
  utm: {
    utm_source: string;
    utm_content: string;
    utm_medium: string;
    utm_term: string;
    utm_campaign: string;
    gclid: string;
    fbclid: string;
    fbc: string;
    fbp: string;
  };
}
