import { StepStatus } from "@allica/ui-react";
import { Stage } from "../PersonalSavings.types";
import { initialStepperConfig } from "./initialStepperConfig";
import { stepOrder } from "./stepOrder";

export const mapStepper = (stageFromApi: string) => {
  const stepperConfig = { ...initialStepperConfig };

  for (let index = 0; index < stepOrder.length; index++) {
    if (index < 7) stepperConfig[stepOrder[index]].status = StepStatus.COMPLETE;
    if (stepOrder[index] === stageFromApi) {
      stepperConfig[stepOrder[index]].status = StepStatus.INCOMPLETE;
      break;
    }
  }

  stepperConfig[Stage.SIGN_UP].disabled = true;
  stepperConfig[Stage.VERIFY].disabled = true;
  return { stepperConfig, currentStep: stageFromApi as Stage };
};
