import { Dispatch, SetStateAction } from "react";

export interface Status {
  pending: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
}

export type StatusCollectionType = Record<keyof Status, Status>;

export interface ErrorResponse<ErrorFields> {
  message?: string;
  code?: string;
  errors?: { field: ErrorFields; reason: string }[];
  status?: number;
  text?: string;
}

interface QueryParams {
  [key: string]: string;
}

export interface RequestOptions {
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  body?: string;
  pathParam?: string;
  queryParams?: QueryParams;
}

export interface ServiceType<ResponseType, ErrorFields> {
  response: ResponseType;
  status: Status;
  error: ErrorResponse<ErrorFields>;
  request: (options?: RequestOptions) => Promise<void>;
  setResponse: (response: ResponseType) => void;
  setStatus?: Dispatch<SetStateAction<Status>>;
}

export const isErrorResponse = <ErrorFields>(
  error: unknown,
): error is ErrorResponse<ErrorFields> => {
  return (
    (error as ErrorResponse<ErrorFields>).code !== undefined ||
    (error as ErrorResponse<ErrorFields>).status !== undefined
  );
};

export interface TrackApiErrorType {
  endpoint: string;
  method: string | undefined;
  headers: Record<string, string>;
  errorResponse: any;
}
