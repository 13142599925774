import { Steps } from "@allica/ui-react";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { ConfirmationOfPayeeResponse } from "src/core/service/confirmation-of-payee-api/ConfirmationOfPayeeApi.types";
import { ProductCatalogItem } from "src/core/service/products-api/ProductsApi.types";

export enum Stage {
  SIGN_UP = "sign-up",
  VERIFY = "verify",
  ABOUT_YOU = "about-you",
  ADDRESS = "address",
  ACCOUNT = "account",
  DEPOSIT = "deposit",
  DOCUMENT = "document",
  SUMMARY = "summary",
}

interface ApplicationSections {
  signUpSection: {
    emailAddress: string;
    phoneNumber: string;
    productReference: string;
    productIssueId: string;
    password?: string;
    domain: "INDIVIDUAL";
  };
  verifySection?: {
    noOfSecurityCodeSent: number;
  };
  aboutYouSection: {
    title: string;
    firstName: string;
    middleName: string | null;
    lastName: string;
    dateOfBirth: string;
    countryOfBirth: string;
    nationalities: string[];
    taxResidencies: { taxCountryCode: string; number: string }[];
  };
  addressSection: {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    county: string;
    postCode: string;
    countryIsoCode: string;
  };
  depositSection: {
    depositAmount: string;
    depositDuration: string;
  };
  accountSection: {
    name: string;
    sortCode: string;
    accountNumber: string;
    copResponse: ConfirmationOfPayeeResponse;
  };
  documentSection: {
    terms: boolean;
    email?: boolean;
    sms?: boolean;
    phone?: boolean;
    mail?: boolean;
  };
  summarySection: {
    detailsVerified: boolean;
  };
  outcome: {
    success: boolean;
    accountDetails?: {
      sortCode?: string;
      accountNumber?: string;
      name: string;
    }
  };
}

interface PersonalSavingBaseType {
  individualApplicationSections: ApplicationSections;
}

type ApplicationSteps = keyof typeof Stage;
export type CurrentApplicationStage =
  | ApplicationSteps
  | "COMPLETED"
  | "RISK_CHECK_IN_PROGRESS"
  | "SPLITTING"
  | "PROCESSING_ERROR"
  | "IN_REVIEW";

export interface PersonalSavingDataTypes extends PersonalSavingBaseType {
  cobApplicationId: string;
  applicationID: string;
  currentApplicationStage?: CurrentApplicationStage | null;
}

export interface PersonalSavingApiType extends ApplicationSections {
  cobApplicationId: string;
  currentApplicationStage?: CurrentApplicationStage | null;
}

export interface PersonalContextType {
  currentStage: Stage | null;
  stepperConfig: Steps<Stage>;
  personalSavingData: PersonalSavingDataTypes;
  setCurrentStage: Dispatch<SetStateAction<Stage | null>>;
  setStepperConfig: Dispatch<SetStateAction<Steps<Stage>>>;
  setPersonalSavingData: Dispatch<React.SetStateAction<PersonalSavingDataTypes>>;
  showGenericError: boolean;
  setShowGenericError: Dispatch<React.SetStateAction<boolean>>;
  isPersonalSavingDataLoading: boolean;
  selectedProductInfo: ProductCatalogItem;
  showNominatedAccountIncomplete: boolean;
  setShowNominatedAccountIncomplete: Dispatch<React.SetStateAction<boolean>>;
}

export type PersonalContextProviderType = {
  applicationID: string | null;
  children?: ReactNode;
  applicationIDStatus: { loading: boolean; success: boolean };
};
