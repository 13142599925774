import { useParams } from "react-router-dom";
import { Button, ChevronRightIcon, Heading, StepStatus, Text } from "@allica/ui-react";
import { Box, FormControl, FormErrorMessage } from "@chakra-ui/react";
import SummaryList from "src/components/summary-list/SummaryList";
import Checkbox from "src/components/checkbox/Checkbox";
import { Stage } from "../../PersonalSavings.types";
import { usePersonalContext } from "../../context/PersonalContext";
import {
  concatenateAddress,
  concatenateName,
  dateFormatReverse,
  formatCurrency,
  formatSortCode,
} from "src/core/utils";
import { Overlay } from "src/components/overlay/Overlay";
import { useEffect, useState } from "react";
import { DepositsAPI } from "src/core/service";
import { useStore } from "src/core/store/StoreContext";
import { getAndJoinDescriptions, getDescription } from "../../../../core/utils";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { appInsights } from "src/core/app/ApplicationInsights";
import ErrorBanner from "src/components/connection-error/ErrorBanner";
import {
  ApplicationStatusResponse,
  StageOptions,
  StatusOptions,
} from "src/core/service/deposits-api/DepositsApi.types";
import { SavingsAccountsAPI, SavingsAccountType } from "../../../../core/service";

const ApplicationSummary = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkedDetails, setCheckedDetails] = useState<boolean>(false);
  const [checkBoxError, setCheckboxError] = useState<boolean>(false);
  const [submitStep, setSubmitStep] = useState<StageOptions | null>(null);

  const {
    stepperConfig,
    setCurrentStage,
    setStepperConfig,
    personalSavingData,
    setShowGenericError,
    selectedProductInfo,
    setShowNominatedAccountIncomplete,
    showNominatedAccountIncomplete,
    setPersonalSavingData,
  } = usePersonalContext();

  const {
    applicationID,
    individualApplicationSections: {
      signUpSection,
      aboutYouSection,
      addressSection: { addressLine1, addressLine2, addressLine3, city, county, postCode },
      depositSection,
      accountSection,
    },
  } = personalSavingData;

  const { individualTitle, countries, nationality } = useStore();
  const params = useParams();

  const {
    status: summaryStatus,
    response: summaryResponse,
    request: submitSummary,
    error: summaryError,
  } = DepositsAPI<ApplicationStatusResponse>(`applications/individuals/${applicationID}/summary`);

  const {
    status: accountOpenStatus,
    response: accountOpenResponse,
    request: accountOpenRequest,
  } = DepositsAPI<ApplicationStatusResponse>(`applications/${applicationID}/status-info`);

  const {
    status: savingsAccountStatus,
    response: savingsAccountResponse,
    request: savingsAccountRequest,
  } = SavingsAccountsAPI<SavingsAccountType>();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!checkedDetails || showNominatedAccountIncomplete) {
      setCheckboxError(true);
    } else {
      setShowGenericError(false);
      submitSummary({
        method: "PATCH",
        body: JSON.stringify({ detailsVerified: true }),
      });
      setIsLoading(true);
      appInsights.trackEvent({ name: `submit-application` });
    }
  };

  const showError = () => {
    setIsLoading(false);
    setShowGenericError(true);
    appInsights.trackEvent({ name: `submit-application-failed` });
  };

  const setComplete = () => {
    const newState = { ...stepperConfig };
    newState[Stage.SUMMARY].status = StepStatus.COMPLETE;
    setStepperConfig(newState);
  };

  const setRefer = () => {
    const newData = { ...personalSavingData };
    newData.individualApplicationSections.outcome = { success: false };
    newData.cobApplicationId =
      summaryResponse.cobApplicationId || accountOpenResponse.cobApplicationId;
    setPersonalSavingData(newData);
    appInsights.trackEvent({ name: `summary-to-refer` });
    setComplete();
  };

  const setSuccess = () => {
    const newData = { ...personalSavingData };
    newData.individualApplicationSections.outcome = { success: true };
    setPersonalSavingData(newData);
    appInsights.trackEvent({ name: `outcome-success` });
    appInsights.trackEvent({ name: `personal-deposits-${params.product}-completed` });
    setComplete();
  };

  useEffect(() => {
    if (summaryStatus.success) {
      let setReferTimer: ReturnType<typeof setTimeout>;
      if (
        summaryResponse.applicationStatus === StatusOptions.REVIEW ||
        summaryResponse.applicationStatus === StatusOptions.FAIL
      ) {
        setRefer();
      } else {
        setReferTimer = setTimeout(setRefer, 15000);
        accountOpenRequest();
      }
      return () => setReferTimer && clearTimeout(setReferTimer);
    }
    if (summaryStatus.error) {
      if (summaryError.status === 504) {
        accountOpenRequest();
      } else {
        showError();
      }
    }
  }, [summaryStatus]);

  useEffect(() => {
    if (accountOpenStatus.success) {
      if (summaryResponse.cobApplicationId) {
        appInsights.trackEvent({
          name: `pob-cob-application-id`,
          properties: { cobID: summaryResponse.cobApplicationId },
        });
      }

      let accountOpenRequestTimer: ReturnType<typeof setTimeout>;

      if (accountOpenResponse.applicationStage === StageOptions.SUMMARY) {
        showError();
      } else if (
        accountOpenResponse.applicationStage === StageOptions.RISK_CHECK_IN_PROGRESS ||
        accountOpenResponse.applicationStage === StageOptions.SPLITTING
      ) {
        accountOpenRequestTimer = setTimeout(accountOpenRequest, 2500);
      } else if (accountOpenResponse.applicationStatus === StatusOptions.COMPLETED) {
        setSuccess();
      } else {
        setRefer(); //IN_REVIEW, PROCESSING_ERROR
      }

      setSubmitStep(accountOpenResponse.applicationStage);
      return () => accountOpenRequestTimer && clearTimeout(accountOpenRequestTimer);
    }
    if (accountOpenStatus.error) {
      setSubmitStep(StageOptions.PROCESSING_ERROR);
      showError();
    }
  }, [accountOpenStatus]);

  useEffect(() => {
    if (!isFeatureActive(FeatureFlag.NEW_OUTCOME_PAGE)) {
      return;
    }

    if (accountOpenResponse?.accountId) {
      setIsLoading(true);

      const accountId = accountOpenResponse.accountId;
      savingsAccountRequest({ pathParam: accountId });

      if (savingsAccountStatus.success && savingsAccountResponse) {
        const newData = { ...personalSavingData };

        newData.individualApplicationSections.outcome.accountDetails = {
          sortCode: savingsAccountResponse.sortCode,
          accountNumber: savingsAccountResponse.accountNumber,
          name: savingsAccountResponse.name,
        };

        setPersonalSavingData(newData);
      }
    }
  }, [
    isFeatureActive(FeatureFlag.NEW_OUTCOME_PAGE),
    accountOpenResponse,
    savingsAccountStatus,
    savingsAccountResponse,
  ]);

  useEffect(() => {
    if (submitStep) appInsights.trackEvent({ name: submitStep.toLowerCase().replace(/_/g, "-") });
  }, [submitStep]);

  useEffect(() => {
    if (stepperConfig[Stage.ACCOUNT].status === StepStatus.ALERT)
      setShowNominatedAccountIncomplete(true);
  }, []);

  const getAccountSummaryList = () => {
    const list = [
      {
        field: "Account",
        value: selectedProductInfo?.description,
      },
    ];

    if (selectedProductInfo?.interest?.rateValue) {
      list.push({
        field: "Interest rate",
        value: `${selectedProductInfo?.interest?.rateValue}% Gross`,
      });
    }

    return list;
  };

  const handleCheckboxChange = () => {
    setCheckboxError(false);
    setCheckedDetails(!checkedDetails);
  };

  const handleEditAboutYou = () => setCurrentStage(Stage.ABOUT_YOU);
  const handleEditAddress = () => setCurrentStage(Stage.ADDRESS);
  const handleEditDeposit = () => setCurrentStage(Stage.DEPOSIT);
  const handleEditNominatedAccount = () => setCurrentStage(Stage.ACCOUNT);

  const accountSummaryList = getAccountSummaryList();
  return (
    <>
      {isLoading && <Overlay text="Submitting application, please wait" spinner />}
      <Heading color="$text-01" size="h1" as="h1" mb="1.6rem" mt="6.4rem">
        Application summary
      </Heading>
      <Text color="$text-03" textStyle="body-03" mb="6.4rem">
        Before submitting your application, please make sure all the details you have supplied are
        correct.
      </Text>
      <SummaryList title="Account" listItems={accountSummaryList} />
      <SummaryList
        title="Sign Up"
        listItems={[
          { field: "Email", value: signUpSection.emailAddress },
          { field: "Mobile number", value: signUpSection.phoneNumber },
        ]}
      />
      <SummaryList
        mb="8rem"
        onEdit={handleEditAboutYou}
        title="About You"
        listItems={[
          {
            field: "Name",
            value: concatenateName({
              title: individualTitle.find((title) => aboutYouSection.title === title.name)
                ?.description,
              firstName: aboutYouSection.firstName,
              middleName: aboutYouSection.middleName,
              lastName: aboutYouSection.lastName,
            }),
          },
          {
            field: "Date of birth",
            value: dateFormatReverse(aboutYouSection.dateOfBirth),
          },
          {
            field: "Country of birth",
            value: getDescription(aboutYouSection.countryOfBirth, countries),
          },
          {
            field: "Nationality",
            value: getAndJoinDescriptions(aboutYouSection.nationalities, nationality),
          },
          {
            field: "Tax Status",
            value: getAndJoinDescriptions(
              aboutYouSection.taxResidencies.map((i) => i.taxCountryCode),
              countries,
            ),
          },
        ]}
      />
      <SummaryList
        mb="8rem"
        onEdit={handleEditAddress}
        title="Your Address"
        listItems={[
          {
            field: "Home Address",
            value: concatenateAddress({
              addressLine1,
              addressLine2,
              addressLine3,
              city,
              county,
              postCode,
            }),
          },
        ]}
      />
      <SummaryList
        mb="8rem"
        onEdit={handleEditDeposit}
        title="Deposit Amount"
        listItems={[
          { field: "Deposit amount", value: formatCurrency(depositSection.depositAmount) },
        ]}
      />
      {showNominatedAccountIncomplete && (
        <Box mb="3.2rem">
          <ErrorBanner
            title="Account page requires new information."
            titleCallback={{
              label: "Go to Account page",
              method: handleEditNominatedAccount,
            }}
          />
        </Box>
      )}
      <SummaryList
        mb="8rem"
        onEdit={handleEditNominatedAccount}
        title="Nominated Account"
        listItems={[
          { field: "Sort code", value: formatSortCode(accountSection.sortCode) },
          { field: "Account number", value: accountSection.accountNumber },
        ]}
      />
      <form onSubmit={handleSubmit}>
        <FormControl isInvalid={checkBoxError}>
          <Checkbox checked={checkedDetails} onChange={handleCheckboxChange}>
            I have checked my details and can confirm they are correct.
          </Checkbox>
          <FormErrorMessage>Please check and confirm your details</FormErrorMessage>
        </FormControl>
        <Button
          mt="8rem"
          isLoading={isLoading}
          loadingText="Submit application"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon />}
        >
          Submit application
        </Button>
      </form>
    </>
  );
};

export default ApplicationSummary;
