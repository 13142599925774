import { ErrorIcon, Input } from "@allica/ui-react";
import { InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";
import { forwardRef } from "react";

export const BaseInput = forwardRef<HTMLInputElement, InputProps>(
  ({ w, type, isInvalid, ...rest }, ref) => (
    <InputGroup w={w ?? "100%"}>
      <Input bg="$ui-02" type={type ? type : "text"} ref={ref} {...rest} w="full" />
      {isInvalid && (
        <InputRightElement>
          <ErrorIcon color={"$danger-01"} data-testid="baseInputErrorIcon" />
        </InputRightElement>
      )}
    </InputGroup>
  )
);
