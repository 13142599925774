export const LOCALE = "en-GB";

export const formatInputCurrency = (value: string | number = "", asInteger = false): string => {
  if (value !== 0 && !value) return "";
  const parsedValue = Number(value);
  if (isNaN(parsedValue)) return "";

  let minimumFractionDigits = 0;
  let maximumFractionDigits = 0;

  if (parsedValue % 1 !== 0 && !asInteger) {
    maximumFractionDigits = 2;
    minimumFractionDigits = 2;
  }

  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(parsedValue);
};

export const validateDepositAmount =
  (isEasyAccess: boolean, minimumDeposit: number, maximumDeposit: number) =>
  (value: string): boolean | string => {
    const numberValue = Number(value);
    const minimumFormattedDeposit = formatInputCurrency(minimumDeposit);
    const maximumFormattedDeposit = formatInputCurrency(maximumDeposit);

    if (isEasyAccess) {
      if (numberValue > maximumDeposit) {
        return `Maximum funding amount exceeded, must be below ${maximumFormattedDeposit}`;
      }

      if (numberValue < minimumDeposit) {
        return `Minimum funding amount not met, must be above ${minimumFormattedDeposit}`;
      }
      return true;
    }

    if (numberValue > maximumDeposit || numberValue < minimumDeposit) {
      return `We can only accept funding between ${minimumFormattedDeposit} and ${maximumFormattedDeposit}`;
    }

    return true;
  };
