import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { appInsights } from "src/core/app/ApplicationInsights";

import { PersonalContextProvider } from "./context/PersonalContext";
import PersonalSavingsLayout from "./layout/PersonalSavingsLayout";
import { DepositsAPI } from "src/core/service";
import { UserApplicationsResponseType } from "src/core/service/deposits-api/DepositsApi.types";

const PersonalSavings = () => {
  const params = useParams();

  const [applicationID, setApplicationID] = useState<string | null>(
    window.atob(sessionStorage.getItem("applicationID") ?? ""),
  );

  const {
    response: userApplicationResponse,
    status: userApplicationStatus,
    request: getUserApplications,
  } = DepositsAPI<UserApplicationsResponseType>(`applications/current-user`);

  useEffect(() => {
    if (!applicationID) {
      const token = sessionStorage.getItem("token");
      token && getUserApplications();
    }
    const traceContext = appInsights.getTraceCtx();
    traceContext?.setName(`personal-deposits-${params.product}`);
  }, []);

  useEffect(() => {
    if (userApplicationStatus.success) {
      const latestApplicationID = userApplicationResponse[0].applicationRef;
      setApplicationID(latestApplicationID);
      sessionStorage.setItem("applicationID", window.btoa(latestApplicationID));
    }
  }, [userApplicationStatus.success]);

  useEffect(() => {
    if (userApplicationStatus.error) {
      throw new Error("Unable to get the current personal application ID");
    }
  }, [userApplicationStatus.error]);

  return (
    <PersonalContextProvider
      applicationID={applicationID}
      applicationIDStatus={userApplicationStatus}
    >
      <PersonalSavingsLayout />
    </PersonalContextProvider>
  );
};

export default PersonalSavings;
