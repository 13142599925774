import { Heading, Text, getHeadingStyle, ErrorIcon } from "@allica/ui-react";
import { Card, InputGroup, InputRightElement, Select, Stack } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useFormContext, FieldValues, Path } from "react-hook-form";
import AddressWrapper from "src/components/address/address-wrapper/AddressWrapper";
import { FormGroup } from "src/components/input-set/FormGroup";
import { BaseInput } from "src/components/input/default/Input";
import InternationalPhoneInput from "src/components/input/international-phone/InternationalPhoneInput";
import { PercentageInput } from "src/components/input/percentage/PercentageInput";
import MaskedInput from "src/components/masked-input/MaskedInput";
import Nationality from "src/components/Nationality/Nationality";
import TaxResidency from "src/components/tax-residency/TaxResidency";
import { useStore } from "src/core/store/StoreContext";
import {
  dateValidate,
  emptyErrorDataHeapId,
  getProperty,
  moveUKToFirstPosition,
} from "src/core/utils";
import { DOBErrorMessage } from "src/shared/applicant/Applicant.enums";
import { dobDataHeapId } from "src/shared/applicant/Applicant.utils";
import { validatePhoneNumber } from "./IndividualDetails.validation";

export interface IndividualDetailsProps<FormType> {
  fieldRootPath?: Path<FormType> | "";
  isMainApplicant?: boolean;
  setTaxStatus?: Dispatch<SetStateAction<string>>;
  taxStatus?: string;
}

const IndividualDetails = <FormType extends FieldValues>({
  fieldRootPath = "",
  isMainApplicant,
}: IndividualDetailsProps<FormType>) => {
  const [showAddNationality, setShowAddNationality] = useState(false);
  const [showManualAdd, setShowManualAdd] = useState(false);

  const referenceData = useStore();
  const countries = moveUKToFirstPosition(referenceData?.countries);

  const {
    register,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const applicantHomeAddress = getValues("applicantHomeAddress");
    setShowManualAdd(!!applicantHomeAddress?.addressLine1);
  }, []);

  const rootPath = fieldRootPath ? `${fieldRootPath}.` : "";
  const errorBaseObject = getProperty(errors, fieldRootPath);

  const { onChange: dateOfBirthRefOnchange, ...restReg } = register(`${rootPath}dateOfBirthSCV`, {
    required: DOBErrorMessage.EMPTY_DOB_ERROR,
    validate: dateValidate,
  });

  const headerStyle = isMainApplicant ? "heading-04" : "heading-05";

  return (
    <Stack gap="6.4rem" mt="3.2rem">
      <Stack gap="3.2rem">
        {isMainApplicant && (
          <Heading color="$text-01" sx={getHeadingStyle(headerStyle)}>
            Personal Details
          </Heading>
        )}
        <FormGroup label="First name" isRequired error={errorBaseObject?.firstName?.message}>
          <BaseInput
            {...register(`${rootPath}firstName`)}
            isInvalid={!!errorBaseObject?.firstName}
            isDisabled
            sx={{ WebkitTextFillColor: "#1A3660" }}
          />
        </FormGroup>

        <FormGroup label="Last name" isRequired error={errorBaseObject?.lastName?.message}>
          <BaseInput
            isInvalid={!!errorBaseObject?.lastName}
            {...register(`${rootPath}lastName`)}
            isDisabled
            sx={{ WebkitTextFillColor: "#1A3660" }}
          />
        </FormGroup>

        <FormGroup label="Role" isRequired error={errorBaseObject?.role?.message}>
          <BaseInput
            isInvalid={!!errorBaseObject?.role}
            {...register(`${rootPath}role`)}
            isDisabled
            sx={{ WebkitTextFillColor: "#1A3660" }}
          />
        </FormGroup>

        <FormGroup
          label="Date of birth"
          isRequired
          info={<Text>DD/MM/YYYY</Text>}
          error={errorBaseObject?.dateOfBirthSCV?.message}
          data-heapid={dobDataHeapId(errors?.dateOfBirthSCV?.message as string)}
        >
          <InputGroup width="24rem">
            <MaskedInput
              maskedPattern="**/**/****"
              maskedChar="*"
              validate={(value: string) => /^\d+$/?.test(value)}
              onChangeHandler={(e) => {
                dateOfBirthRefOnchange(e);
              }}
              data-heapid="dob-applicant-enter"
              {...restReg}
            />
            <InputRightElement>
              {!!errorBaseObject?.dateOfBirthSCV && <ErrorIcon color="$danger-01" />}
            </InputRightElement>
          </InputGroup>
        </FormGroup>

        <FormGroup
          label="Country of birth"
          isRequired
          error={errorBaseObject?.countryOfBirth?.message}
          data-heapid={emptyErrorDataHeapId(errors?.countryOfBirth?.type as string, "countrybirth")}
        >
          <Select
            {...register(`${rootPath}countryOfBirth`, {
              required: "Please select a country of birth",
            })}
            placeholder="Please select"
            data-heapid="countrybirth-list-select"
          >
            {countries?.map((item) => (
              <option key={item?.name} value={item?.name}>
                {item?.description}
              </option>
            ))}
          </Select>
        </FormGroup>

        <Nationality
          fieldRootPath={fieldRootPath}
          showAddNationality={showAddNationality}
          setShowAddNationality={setShowAddNationality}
        />
        {isMainApplicant && (
          <>
            <FormGroup isRequired label="Home Address">
              <Card
                variant="outline"
                bgColor="$ui-01"
                px="3.2rem"
                mt={"1.6rem"}
                paddingTop={"2.4rem"}
                paddingBottom={"4rem"}
              >
                <AddressWrapper
                  addressTypeLabel={""}
                  addressHelperText={"Start typing your address or postcode"}
                  showManualAdd={showManualAdd}
                  setShowManualAdd={setShowManualAdd}
                  fieldRootPath={"applicantHomeAddress"}
                  heapId={{
                    onAddressSearchEnter: "home-address-lookup",
                    onManualButtonClick: "home-address-manual",
                    onEmptyError: "home-empty-error",
                  }}
                />
              </Card>
            </FormGroup>
          </>
        )}
      </Stack>
      {!isMainApplicant && (
        <Stack gap="3.2rem">
          <Heading color="$text-01" sx={getHeadingStyle(headerStyle)}>
            Contact details
          </Heading>

          <FormGroup label="Email" error={errorBaseObject?.email?.message} isRequired>
            <BaseInput
              isInvalid={!!errorBaseObject?.email}
              type="email"
              {...register(`${rootPath}email`, {
                required: "Please enter your email address",
                setValueAs: (value) => value?.trim(),
                pattern: {
                  value:
                    /^(?=^.{0,100}$)[a-zA-Z0-9]([\w.'!#$%&*+/=?^`{|}~-])*([a-zA-Z0-9])+@([a-zA-Z0-9]+([.-]))+[a-zA-Z0-9]{2,8}$/i,
                  message: "Please enter a valid email address",
                },
              })}
            />
          </FormGroup>

          <FormGroup label="Phone number" error={errorBaseObject?.phone?.message} isRequired>
            <Controller
              control={control}
              rules={{ required: "Please enter your phone number", validate: validatePhoneNumber }}
              name={`${rootPath}phone`}
              render={({ field }) => (
                <InternationalPhoneInput
                  w="31.2rem"
                  isInvalid={!!errorBaseObject?.phone}
                  {...field}
                />
              )}
            />
          </FormGroup>
        </Stack>
      )}

      <Stack gap="3.2rem">
        <Heading color="$text-01" sx={getHeadingStyle(headerStyle)}>
          Financial details
        </Heading>

        <FormGroup
          label="Shareholding"
          error={errorBaseObject?.shareHoldingPercentage?.message}
          data-heapid={emptyErrorDataHeapId(
            errors?.shareHoldingPercentage?.type as string,
            "shareholding",
          )}
          isRequired
        >
          <Controller
            control={control}
            name={`${rootPath}shareHoldingPercentage`}
            rules={{
              required: "Please enter shareholding from 0-100",
              min: {
                value: 0,
                message: "Please enter shareholding from 0-100",
              },
              max: {
                value: 100,
                message: "Please enter shareholding from 0-100",
              },
            }}
            render={({ field }) => (
              <PercentageInput
                w="15.2rem"
                isInvalid={!!errors?.shareHoldingPercentage}
                data-heapid="shareholding-number-entry"
                {...field}
              />
            )}
          />
        </FormGroup>
        {isMainApplicant && (
          <TaxResidency
            taxDescription={
              "You must be a UK taxpayer to open this account. If you are a resident for tax in another jurisdictions, including the US, please add below."
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export default IndividualDetails;
