import { Text } from "@allica/ui-react";
import { ListItem, OrderedList } from "@chakra-ui/react";
import { Modal } from "src/components/modal/Modal";

interface ReCaptchaErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  primaryAction: () => void;
}

export const ReCaptchaErrorModal = ({
  isOpen,
  onClose,
  primaryAction,
}: ReCaptchaErrorModalProps) => {
  return (
    <Modal
      headerTitle="reCAPTCHA Error"
      primaryButtonText="Close"
      primaryAction={primaryAction}
      isOpen={isOpen}
      onClose={onClose}
      variant="error"
      data-heapid="recaptcha-modal-error"
    >
      <Text as="div" textStyle="body-03" color="$text-02">
        We use the Google reCAPTCHA tool to protect our application from fraud and other influences.
        We have been unable to connect to reCAPTCHA in this session. Can you please consider:
        <OrderedList styleType="number" paddingTop="1rem">
          <ListItem>Make sure your browser is up to date</ListItem>
          <ListItem>Try using a different browser or device entirely</ListItem>
          <ListItem>Try disabling browser plugins, as they may restrict reCAPTCHA</ListItem>
          <ListItem>Review your connection, for example VPN restrictions or Proxy Server</ListItem>
        </OrderedList>
      </Text>
    </Modal>
  );
};
