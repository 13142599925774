import { ConfirmationOfPayeeResponse } from "src/core/service/confirmation-of-payee-api/ConfirmationOfPayeeApi.types";

export const getMatchType = (copResponse: ConfirmationOfPayeeResponse) => {
  if (copResponse.matched) return "MATCH";
  if (!copResponse.errors) return "UNABLE_TO_VERIFY";

  const unableToVerify = copResponse.errors.some((error) => error.errorType === "UNABLE_TO_VERIFY");

  if (unableToVerify) return "UNABLE_TO_VERIFY";

  const partialMatch = copResponse.errors.some((error) => error.errorType === "PARTIAL_MATCH");
  const wrongAccountType = copResponse.errors.some(
    (error) => error.field === "ACCOUNT_TYPE" && error.errorType === "NO_MATCH"
  );
  if (partialMatch || wrongAccountType) return "PARTIAL_MATCH";

  return "NO_MATCH";
};
