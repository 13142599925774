import { Button, getHeadingStyle, getTextStyle, Heading, PlusIcon } from "@allica/ui-react";
import { Box, Card, Flex, Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import { BinIcon } from "src/components/icon";
import { FormGroup } from "src/components/input-set/FormGroup";
import { useStore } from "src/core/store/StoreContext";
import {
  emptyErrorDataHeapId,
  getFilteredOptions,
  getProperty,
  getSentenceCase,
  moveBritishToFirstPosition,
} from "src/core/utils";
import { AddNationalityProps } from "../Nationality.types";
import { numberMapper } from "../Nationality.utils";

const AddNationality = <FormType extends FieldValues>({
  nationalityNumber,
  handleAddClick,
  handleDeleteClick,
  handleNationalityChange,
  allNationalities,
  changedNationality,
  fieldRootPath,
}: AddNationalityProps<FormType>) => {
  const referenceData = useStore();
  const rootPath = fieldRootPath ? `${fieldRootPath}.` : "";

  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const errorDetails = getProperty(errors, fieldRootPath)?.nationalities;
  const nationalities = moveBritishToFirstPosition(referenceData?.nationality);
  const [nationalitiesOptions, setNationalitiesOptions] = useState(nationalities);
  const data = getValues();
  const currentNationalityCode = getValues(`${rootPath}nationalities.${nationalityNumber}.value`);

  useEffect(() => {
    const usedNationalities = getProperty(data, fieldRootPath)?.nationalities?.map(
      (item: { value: string }) => item.value,
    );
    const filteredNationalities = getFilteredOptions(
      currentNationalityCode,
      usedNationalities,
      nationalities,
    );
    setNationalitiesOptions(filteredNationalities);
  }, [changedNationality, allNationalities]);

  return (
    <>
      <Card
        variant="outline"
        px="3.2rem"
        mt={nationalityNumber === 1 ? "1.6rem" : "0.2rem"}
        bgColor="$ui-01"
      >
        <Flex justifyContent={"space-between"} mt={"2.4rem"} mb={"4rem"}>
          <Heading sx={getHeadingStyle("heading-05")} color="$interactive-02">
            {getSentenceCase(numberMapper[nationalityNumber + 1])} nationality
          </Heading>
          <Button
            variant="text"
            color={"$danger-01"}
            sx={getTextStyle("body-03")}
            leftIcon={<BinIcon boxSize="2rem" />}
            onClick={() => handleDeleteClick(nationalityNumber)}
            iconSpacing="0.8rem"
            data-heapid="nationality-additional-delete"
          >
            Delete
          </Button>
        </Flex>

        <FormGroup
          label="Nationality"
          isRequired
          mb={"3.2rem"}
          error={errorDetails?.[nationalityNumber]?.value?.message}
          data-heapid={emptyErrorDataHeapId(
            errorDetails?.[nationalityNumber]?.value?.type,
            "nationality",
          )}
        >
          <Select
            placeholder="Please select"
            data-heapid="nationality-additional-select"
            {...register(`${rootPath}nationalities.${nationalityNumber}.value`, {
              required: "Please select a nationality",
              onChange: (e: Event) => handleNationalityChange(e, nationalityNumber),
            })}
          >
            {nationalitiesOptions?.map((item) => (
              <option key={item?.name} value={item?.name}>
                {item.description}
              </option>
            ))}
          </Select>
        </FormGroup>

        {nationalityNumber + 1 !== 10 && allNationalities?.length === nationalityNumber + 1 && (
          <Box mb={"4rem"}>
            <Button
              variant={"text"}
              leftIcon={<PlusIcon />}
              onClick={() => handleAddClick(nationalityNumber)}
              iconSpacing="0.8rem"
            >
              Add {numberMapper[nationalityNumber + 2]} nationality
            </Button>
          </Box>
        )}
      </Card>
    </>
  );
};

export default AddNationality;
