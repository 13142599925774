import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, getHeadingStyle, getTextStyle, Heading, PlusIcon } from "@allica/ui-react";
import { Box, Card, Flex, Select } from "@chakra-ui/react";
import { BinIcon } from "src/components/icon";
import { FormGroup } from "src/components/input-set/FormGroup";
import { useStore } from "src/core/store/StoreContext";
import { numberMapper } from "src/pages/personal-savings/stages/about-you/AboutYou.utils";
import { AddCountryProps } from "./CountryOfOperations.types";
import { getFilteredOptions } from "src/core/utils";

const AddCountryOfOperations = ({
  countryNumber,
  handleAddClick,
  handleDeleteClick,
  handleCountryChange,
  allCountries,
  changedCountry,
}: AddCountryProps) => {
  const referenceData = useStore();
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const countries = referenceData?.countries?.filter((country) => country?.name !== "GB");
  const [countriesOptions, setCountriesOptions] = useState(countries);
  const data = getValues();
  const currentCountryCode = getValues(`countries.${countryNumber}.value`);

  useEffect(() => {
    const usedCountries = data?.countries?.map((item: any) => item.value);
    const filteredCountries = getFilteredOptions(currentCountryCode, usedCountries, countries);
    setCountriesOptions(filteredCountries);
  }, [changedCountry, allCountries]);
  return (
    <>
      <Card
        variant="outline"
        px="3.2rem"
        mt={countryNumber === 0 ? "1.6rem" : "0.2rem"}
        bgColor="$ui-01"
      >
        <Flex justifyContent={"space-between"} mt={"2.4rem"} mb={"4rem"}>
          <Heading sx={getHeadingStyle("heading-05")} color="$interactive-02">
            Country of operation {countryNumber + 2}
          </Heading>
          <Button
            variant="text"
            color={"$danger-01"}
            sx={getTextStyle("body-03")}
            leftIcon={<BinIcon boxSize="2rem" />}
            onClick={() => handleDeleteClick(countryNumber)}
            iconSpacing="0.8rem"
            data-heapid="operation-country-cancel"
          >
            Delete
          </Button>
        </Flex>

        <FormGroup
          label="Country"
          isRequired
          mb={"4rem"}
          error={(errors?.countries as any)?.[countryNumber]?.value?.message}
        >
          <Select
            placeholder="Please select"
            data-heapid="operation-country-select"
            {...register(`countries.${countryNumber}.value`, {
              required: "Please select a country from the list",
              onChange: (e: Event) => handleCountryChange(e, countryNumber),
            })}
          >
            {countriesOptions?.map((item) => (
              <option key={item?.name} value={item?.name}>
                {item.description}
              </option>
            ))}
          </Select>
        </FormGroup>

        {countryNumber + 1 !== 10 && allCountries?.length === countryNumber + 1 && (
          <Box mb={"4rem"}>
            <Button
              variant={"text"}
              leftIcon={<PlusIcon />}
              onClick={() => handleAddClick(countryNumber)}
              iconSpacing="0.8rem"
            >
              Add {numberMapper[countryNumber + 3]} country
            </Button>
          </Box>
        )}
      </Card>
    </>
  );
};

export default AddCountryOfOperations;
