import { InputProps } from "@chakra-ui/react";
import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import { formatInputCurrency } from "./CurrencyInput.utils";
import { BaseInput } from "../default/Input";

interface CurrencyInputProps extends InputProps {
  asInteger?: boolean;
}

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ value, onChange, asInteger = false, ...rest }, ref) => {
    const [inputValue, setValue] = useState(formatInputCurrency(value?.toString()?.trim()));

    useEffect(() => {
      if (inputValue.replace(/,|£/g, "") !== value) {
        setValue(formatInputCurrency(value?.toString()?.trim(), asInteger));
      }
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === ".") return;
      if (asInteger && e.target.value.includes(".")) return;

      const currencyRegex = /^£?\d*(\.)?(\d{1,2})?$/;
      const decimalRegex = /^£\d*\.\d{0,2}$/;
      const valueWithoutCommas = e.target.value.replace(/,/g, "");
      const hasDecimal = decimalRegex.test(valueWithoutCommas);
      const isValid = currencyRegex.test(valueWithoutCommas) || e.target.value === "";

      if (hasDecimal) setValue(e.target.value);

      if (isValid) {
        e.target.value = e.target.value.replace(/,|£/g, "");
        onChange && onChange(e);

        if (e.target.value === "") {
          setValue("");
        } else if (!hasDecimal) {
          setValue(formatInputCurrency(e.target.value, asInteger));
        }
      }
    };
    return (
      <BaseInput
        type="text"
        inputMode={`${asInteger ? "numeric" : "decimal"}`}
        ref={ref}
        value={inputValue}
        onChange={handleChange}
        {...rest}
      />
    );
  }
);
