import { StepStatus } from "@allica/ui-react";
import { PropsWithChildren } from "react";
import { Navigate, Route, useParams } from "react-router-dom";
import { usePersonalContext } from "../context/PersonalContext";
import { Stage } from "../PersonalSavings.types";
import {
  AboutYou,
  Address,
  ApplicationSummary,
  Deposit,
  NominatedAccount,
  PersonalSignUp,
  Verify,
  Documents,
} from "../stages";
import { personalSavingsUrl } from "../PersonalSavings.utils";

export const ProtectedRoute = ({ stage, children }: PropsWithChildren<{ stage: Stage }>) => {
  const { stepperConfig, currentStage } = usePersonalContext();
  const { disabled, status } = stepperConfig[stage as Stage];
  const params = useParams();

  if (disabled || status === StepStatus.INACTIVE) {
    if ((stage === Stage.SIGN_UP || stage === Stage.VERIFY) && !currentStage) {
      return <Navigate to={personalSavingsUrl(params.product)} replace />;
    }

    if (currentStage) {
      return <Navigate to={personalSavingsUrl(params.product, currentStage)} replace />;
    }
  }

  return <>{children}</>;
};

const PersonalSavingsRoutes = [
  <Route
    path={`${Stage.SIGN_UP}`}
    element={
      <ProtectedRoute stage={Stage.SIGN_UP}>
        <PersonalSignUp />
      </ProtectedRoute>
    }
    key={`${Stage.SIGN_UP}`}
  />,
  <Route
    path={`${Stage.VERIFY}`}
    element={
      <ProtectedRoute stage={Stage.VERIFY}>
        <Verify />
      </ProtectedRoute>
    }
    key={`${Stage.VERIFY}`}
  />,
  <Route
    path={`${Stage.ABOUT_YOU}`}
    element={
      <ProtectedRoute stage={Stage.ABOUT_YOU}>
        <AboutYou />
      </ProtectedRoute>
    }
    key={`${Stage.ABOUT_YOU}`}
  />,
  <Route
    path={`${Stage.ADDRESS}`}
    element={
      <ProtectedRoute stage={Stage.ADDRESS}>
        <Address />
      </ProtectedRoute>
    }
    key={`${Stage.ADDRESS}`}
  />,
  <Route
    path={`${Stage.DEPOSIT}`}
    element={
      <ProtectedRoute stage={Stage.DEPOSIT}>
        <Deposit />
      </ProtectedRoute>
    }
    key={`${Stage.DEPOSIT}`}
  />,
  <Route
    path={`${Stage.ACCOUNT}`}
    element={
      <ProtectedRoute stage={Stage.ACCOUNT}>
        <NominatedAccount />
      </ProtectedRoute>
    }
    key={`${Stage.ACCOUNT}`}
  />,
  <Route
    path={`${Stage.DOCUMENT}`}
    element={
      <ProtectedRoute stage={Stage.DOCUMENT}>
        <Documents />
      </ProtectedRoute>
    }
    key={`${Stage.DOCUMENT}`}
  />,
  <Route
    path={`${Stage.SUMMARY}`}
    element={
      <ProtectedRoute stage={Stage.SUMMARY}>
        <ApplicationSummary />
      </ProtectedRoute>
    }
    key={`${Stage.SUMMARY}`}
  />,
];

export default PersonalSavingsRoutes;
