import { Select } from "@chakra-ui/react";
import { FormGroup } from "src/components/input-set/FormGroup";
import { useStore } from "src/core/store/StoreContext";
import { BaseInput } from "src/components/input/default/Input";
import { useFormContext } from "react-hook-form";
import { ManualAddressProps } from "./ManualAddress.types";
import { getObjectValues } from "../../manage-address/manageAddress.utils";
import { moveUKToFirstPosition } from "src/core/utils";

function ManualAddress({ fieldRootPath = "" }: ManualAddressProps) {
  const referenceData = useStore();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const countries = moveUKToFirstPosition(referenceData?.countries);

  return (
    <>
      <FormGroup
        label="Address line 1"
        mb="3.2rem"
        isRequired
        error={getObjectValues(errors, `${fieldRootPath}addressLine1`)?.message}
      >
        <BaseInput
          isInvalid={!!getObjectValues(errors, `${fieldRootPath}addressLine1`)}
          {...register(`${fieldRootPath}addressLine1`, {
            required: "Please enter a valid address",
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup
        label="Address line 2"
        mb="3.2rem"
        error={getObjectValues(errors, `${fieldRootPath}addressLine2`)?.message}
      >
        <BaseInput
          isInvalid={!!getObjectValues(errors, `${fieldRootPath}addressLine2`)}
          {...register(`${fieldRootPath}addressLine2`, {
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup
        label="Address line 3"
        mb="3.2rem"
        error={getObjectValues(errors, `${fieldRootPath}addressLine3`)?.message}
      >
        <BaseInput
          isInvalid={!!getObjectValues(errors, `${fieldRootPath}addressLine3`)}
          {...register(`${fieldRootPath}addressLine3`, {
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup
        label="Town/city"
        mb="3.2rem"
        isRequired
        error={getObjectValues(errors, `${fieldRootPath}city`)?.message}
      >
        <BaseInput
          w="24.5rem"
          isInvalid={!!getObjectValues(errors, `${fieldRootPath}city`)}
          {...register(`${fieldRootPath}city`, {
            required: "Please enter a valid town or city",
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup
        label="County"
        mb="3.2rem"
        isRequired
        error={getObjectValues(errors, `${fieldRootPath}county`)?.message}
      >
        <BaseInput
          isInvalid={!!getObjectValues(errors, `${fieldRootPath}county`)}
          w="24.5rem"
          {...register(`${fieldRootPath}county`, {
            required: "Please enter a valid County",
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup
        label="Postcode"
        mb="3.2rem"
        isRequired
        error={getObjectValues(errors, `${fieldRootPath}postCode`)?.message}
      >
        <BaseInput
          w="24.5rem"
          isInvalid={!!getObjectValues(errors, `${fieldRootPath}postCode`)}
          {...register(`${fieldRootPath}postCode`, {
            required: "Please enter a valid postcode",
            setValueAs: (value) => value?.trim(),
          })}
        />
      </FormGroup>

      <FormGroup
        label="Country"
        isRequired
        error={getObjectValues(errors, `${fieldRootPath}countryIsoCode`)?.message}
      >
        <Select
          width="24.5rem"
          sx={{
            paddingRight: "4rem",
          }}
          {...register(`${fieldRootPath}countryIsoCode`, {
            required: "country should not be empty",
          })}
        >
          {countries?.map((country) => (
            <option key={country?.name} value={country?.name}>
              {country?.description}
            </option>
          ))}
        </Select>
      </FormGroup>
    </>
  );
}

export default ManualAddress;
