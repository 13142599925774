import { ReactNode } from "react";
import features, { FeatureFlag } from "../../environments/feature.flags";
import environment from "../../environments/environment";

type FeatureFlagProps = {
  name: FeatureFlag;
  children: ReactNode;
  story?: string; //https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-workflow-statuses
};

type envName = "dev" | "prep" | "prod";

const isFeatureActive = (name: FeatureFlag) => {
  let isActive = false;
  let envName = environment.name === "local" ? "dev" : (environment.name as envName);
  if (features[name] && features[name][envName]) {
    isActive = features[name][envName] ? features[name][envName] : false;
  }
  return isActive;
};

const FeatureActive = ({ name, children }: FeatureFlagProps) => {
  return <>{isFeatureActive(name) ? children : ""}</>;
};

const FeatureToBeHidden = ({ name, children }: FeatureFlagProps) => {
  return <>{isFeatureActive(name) ? "" : children}</>;
};

const describeIf = (featureFlag: FeatureFlag) => {
  return isFeatureActive(featureFlag) ? describe : describe.skip;
};

const testIf = (featureFlag: FeatureFlag) => {
  return isFeatureActive(featureFlag) ? test : test.skip;
};

export { FeatureActive, FeatureToBeHidden, isFeatureActive, describeIf, testIf };
