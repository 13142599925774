import { scvRefDefault, ScvReferenceData } from "src/core/service/deposits-api/DepositsApi.types";
import { ProductCatalogItem } from "src/core/service/products-api/ProductsApi.types";
import { StoreContextType } from "./reference.types";

export const referenceDefaultValues: ScvReferenceData = {
  businessNatures: [] as scvRefDefault[],
  businessIndividualRelationTypes: [] as scvRefDefault[],
  countries: [] as scvRefDefault[],
  nationality: [] as scvRefDefault[],
  sicCodes: [] as scvRefDefault[],
  individualTitle: [] as scvRefDefault[],
  phonePrefix: [] as scvRefDefault[],
  importantDocuments: [] as scvRefDefault[],
  products: [] as ProductCatalogItem[],
  productCatalog: [],
};

export const StoreDefaultValues: StoreContextType = {
  ...referenceDefaultValues,
  storeStatus: { success: false, error: false },
};
