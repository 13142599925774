import { useEffect } from "react";
import { Text } from "@allica/ui-react";

import { appInsights } from "src/core/app/ApplicationInsights";
import { Modal } from "src/components/modal/Modal";

interface ExitApplicationModalProps {
  isOpen: boolean;
  onClose: () => void;
  exitAction: () => void;
  exitByStepper?: boolean;
}

const getMessage = (leaveViaStepper?: boolean) => {
  if (leaveViaStepper) {
    return "You are unable to make changes to the sign up details you have given during the application process. You can complete the application and call our Customer Service team on 0330 0943333 should you need to update your email and phone number.";
  }

  return "If you would like to go back please use the navigation on the left hand side of the page.";
};

export const ExitApplicationModal = ({
  isOpen,
  onClose,
  exitAction,
  exitByStepper,
}: ExitApplicationModalProps) => {
  const message = getMessage(exitByStepper);

  useEffect(() => {
    if (isOpen) appInsights.trackEvent({ name: `are-you-sure-you-want-to-leave` });
  }, [isOpen]);

  return (
    <Modal
      headerTitle="Are you sure you want to leave?"
      primaryButtonText="Continue application"
      secondaryButtonText="Leave application"
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={onClose}
      secondaryAction={exitAction}
      variant="alert"
      closeOnOverlayClick={false}
    >
      <Text as="p" textStyle="body-03" color="$text-02" mb="3.2rem">
        {message}
      </Text>
    </Modal>
  );
};
