import { Box, Flex, Image } from "@chakra-ui/react";
import environment from "src/environments/environment";
import logo from "../../styles/svg/logo.svg";

export interface HeaderProps {
  setShowExitApplicationModal?: (show: boolean) => void;
  setExitByStepper?: (show: boolean) => void;
  applicationComplete?: boolean;
  applicationStarted?: boolean;
}

const Header = ({
  setShowExitApplicationModal,
  setExitByStepper,
  applicationComplete,
  applicationStarted,
}: HeaderProps) => {
  const goToAllicaPortal = applicationComplete || !applicationStarted;

  const handleClick = () => {
    if (goToAllicaPortal) {
      window.location.href = environment.ui.allicaPortal;
    } else {
      setExitByStepper && setExitByStepper(false);
      setShowExitApplicationModal && setShowExitApplicationModal(true);
    }
  };

  return (
    <Flex
      position="fixed"
      zIndex={999}
      top="0"
      as="header"
      bg="darkdenim"
      p={"2.4rem 3.2rem"}
      h="7.2rem"
      w="100%"
      alignItems="center"
    >
      <Flex as="nav" w="100%" justify="space-between">
        <Box
          as="a"
          _hover={{ cursor: "pointer" }}
          onClick={handleClick}
          href={goToAllicaPortal ? environment.ui.allicaPortal : undefined}
          data-heapid="allica-homepage-button"
        >
          <Image
            src={logo}
            alt="Allica Bank - logo"
            w={{ base: "120px", lg: "216.25px" }}
            h={{ base: "16px", lg: "28px" }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Header;
