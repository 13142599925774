import { getTextStyle, Text } from "@allica/ui-react";
import { Box } from "@chakra-ui/react";

export const FooterInformation = () => {
  return (
    <Box>
      <Text mt="3.2rem" color="$text-02" sx={getTextStyle("body-03")}>
        If you have not received an email confirmation within 5 working days, please check your junk
        email folder as communications from a new contact can often get redirected. To stop this
        happening, you may want to add{" "}
        <a href="mailto:customer.services@allica.bank" style={{ textDecoration: "underline" }}>
          customer.services@allica.bank
        </a>{" "}
        to your email's contact list now to make sure you receive our communications. If you have
        done this and still not received the email after 5 working days, then please let us know via
        the Customer Services email address or call on <a href="tel:03300943333">0330 094 3333</a>.
      </Text>
    </Box>
  );
};
