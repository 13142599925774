import { IndividualDetailsType } from "src/shared/company/Company.types";
import { OtherParties, Stage } from "../../BusinessRewards.types";
import {
  MIN_SHAREHOLDING,
  MIN_SIGNIFICANT_INDIVIDUALS,
  MAX_SIGNIFICANT_INDIVIDUALS,
  PARTIES_STEP_ORDER_INDEX,
  ROLES,
} from "./businessRewardsContext.constants";

/**
 * Determines if a connected individual is significant. The parameters that define
 * a significant individual can be found in `businessRewardsContext.constants.ts`.
 *
 * @param connectedIndividuals - individuals connected to the company (data from companies house)
 */
export const isIndividualSignificant = ({
  role,
  shareHoldingPercentage,
}: IndividualDetailsType) => {
  const isSignificantRole = role && ROLES.includes(role.toLowerCase());
  const isSignificantShareholding =
    shareHoldingPercentage && shareHoldingPercentage >= MIN_SHAREHOLDING;

  return isSignificantRole || isSignificantShareholding;
};

/**
 * Determines if the other parties step should be shown based on the number
 * of significant individuals connected to a company.
 *
 * @param connectedIndividuals - individuals connected to the company (data from companies house)
 */
export const shouldOtherPartiesStepBeShown = (
  connectedIndividuals: IndividualDetailsType[],
): boolean => {
  let significantIndividualCount = 0;

  for (const individual of connectedIndividuals) {
    if (isIndividualSignificant(individual)) significantIndividualCount++;
    if (significantIndividualCount > MAX_SIGNIFICANT_INDIVIDUALS) break;
  }

  return (
    significantIndividualCount >= MIN_SIGNIFICANT_INDIVIDUALS &&
    significantIndividualCount <= MAX_SIGNIFICANT_INDIVIDUALS
  );
};

/**
 * Returns an array of significant individuals with information to populate the `otherPartiesSection` within
 * `BusinessRewardsContext`
 *
 * @param connectedIndividuals - individuals connected to the company (data from companies house)
 */
export const parseIndividuals = (connectedIndividuals: IndividualDetailsType[]) => {
  return connectedIndividuals.reduce<OtherParties[]>((prev, curr) => {
    if (isIndividualSignificant(curr)) {
      const { firstName, lastName, role } = curr;
      return [...prev, { firstName, lastName, role }];
    }
    return prev;
  }, []);
};

/**
 * Determines a new `stepOrder` based on given parameters. Returns `null` if there is no change.
 *
 * @param stepOrder - list of stages (Stage[])
 * @param hasPartiesStep - does the stepOrderInclude Stage.PARTIES
 * @param shouldAddParties - should the stepOrder include Stage.PARTIES
 */
export const getStepOrder = ({
  stepOrder,
  hasPartiesStep,
  shouldAddParties,
}: {
  stepOrder: Required<Stage>[];
  hasPartiesStep: boolean;
  shouldAddParties: boolean;
}): Stage[] | null => {
  if (hasPartiesStep && !shouldAddParties) {
    return stepOrder.filter((stage) => stage !== Stage.PARTIES);
  } else if (!hasPartiesStep && shouldAddParties) {
    const newStepOrder = [...stepOrder];
    newStepOrder.splice(PARTIES_STEP_ORDER_INDEX, 0, Stage.PARTIES);
    return newStepOrder;
  }

  return null;
};
