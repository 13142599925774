import {
  Button,
  CheckboxGroup,
  ChevronRightIcon,
  Heading,
  StepStatus,
  Text,
} from "@allica/ui-react";
import { FormControl, FormErrorMessage, FormLabel, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TaskChecklist } from "src/components/task-checklist/TaskChecklist";
import { useStore } from "src/core/store/StoreContext";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { DocumentFormValues } from "../../../../shared/documents/Documents.types";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { Stage } from "../../BusinessRewards.types";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { DepositsAPI } from "src/core/service";
import { convertToDocumentSectionStructure } from "src/shared/documents/Documents.utils";

const BusinessRewardsDocuments = () => {
  const {
    handleSubmit,
    setError,
    control,
    getValues,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm<DocumentFormValues>();

  const { importantDocuments } = useStore();

  const documentsLinks = importantDocuments?.reduce((acc: Record<string, string>, item) => {
    acc[item?.name] = item?.description;
    return acc;
  }, {});

  const {
    setCurrentStage,
    updateStepperConfig,
    businessRewardsData,
    setBusinessRewardsData,
    setShowGenericError,
  } = useBusinessRewardsContext();

  const [allDocumentsRead, setAllDocumentsRead] = useState(
    // prevent requirement to click all three documents in dev and prep
    isFeatureActive(FeatureFlag.DOCUMENT_SKIP_LINKS) ||
      businessRewardsData?.businessRewardsApplicationSections?.documentSection?.terms ||
      false,
  );
  const [displayChecklistErrors, setDisplayChecklistErrors] = useState(false);

  const {
    status: documentStatus,
    request: saveDocumentData,
    error: documentError,
  } = DepositsAPI(`applications/businesses/${businessRewardsData?.applicationID}/document`);

  const onSubmit = (data: DocumentFormValues) => {
    const payload = convertToDocumentSectionStructure(data);
    if (businessRewardsData?.applicationID) {
      saveDocumentData({
        method: "PATCH",
        body: JSON.stringify(payload),
      });
    }
  };

  useEffect(() => {
    const marketingPreferences = [];
    const documentTerms = [];
    const data: Record<string, boolean> =
      businessRewardsData?.businessRewardsApplicationSections?.documentSection || {};
    for (const key in data) {
      if (key === "terms" && data?.[key]) {
        documentTerms.push(key);
      } else if (key && data?.[key]) {
        marketingPreferences.push(key);
      }
    }
    reset({ documentTerms, marketingPreferences });
  }, [businessRewardsData?.businessRewardsApplicationSections?.documentSection]);

  useEffect(() => {
    if (documentStatus.success) {
      const newData = convertToDocumentSectionStructure(getValues());
      const newBusinessRewardsData = { ...businessRewardsData };
      newBusinessRewardsData.businessRewardsApplicationSections.documentSection = { ...newData };
      setBusinessRewardsData(newBusinessRewardsData);

      setCurrentStage(Stage.SUMMARY);
      updateStepperConfig([
        { stage: Stage.DOCUMENT, value: { status: StepStatus.COMPLETE } },
        { stage: Stage.SUMMARY, value: { status: StepStatus.INCOMPLETE } },
      ]);
    }
  }, [documentStatus.success]);

  useEffect(() => {
    if (documentStatus.error) {
      if (documentError.code === "VALIDATION_ERROR") {
        documentError?.errors?.forEach((data) => {
          const { field, reason } = data;
          if (field === "terms") {
            setError("documentTerms", { message: reason });
          } else {
            setError("marketingPreferences", { message: reason });
          }
        });
      } else setShowGenericError(true);
    }
  }, [documentStatus.error]);

  return (
    <>
      <Heading color="$text-01" size="h1" as="h1" mb="1.6rem" mt="6.4rem">
        Important documents
      </Heading>
      <Text color="$text-03" textStyle="body-03" mb="6.4rem">
        Please open and review the following documents.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TaskChecklist
          items={[
            {
              taskId: "productSummary",
              label: "Product Information",
              href: "https://www.allica.bank/business-current-account/key-product-information",
            },
            {
              taskId: "termsAndConditions",
              label: "Business Rewards Account Terms and Conditions",
              href: "https://www.allica.bank/business-current-account/terms-and-conditions",
            },
            {
              taskId: "infoSheet",
              label: "FSCS Info Sheet and Exclusion List",
              href: documentsLinks?.["FCS_INFO_SHEET"],
            },
          ]}
          onComplete={() => {
            const { documentTerms } = getValues();
            if (!allDocumentsRead && documentTerms?.length) {
              clearErrors("documentTerms");
            }
            setAllDocumentsRead(true);
          }}
          displayErrors={displayChecklistErrors}
          status={
            allDocumentsRead
              ? {
                  productSummary: "complete",
                  termsAndConditions: "complete",
                  infoSheet: "complete",
                }
              : undefined
          }
        />

        <Text mb="1.6rem">
          See a full list of{" "}
          <Link
            color="$link-01"
            href={"https://www.allica.bank/fees/rewards-account-fees-and-limits"}
            target="_blank"
          >
            limits, fees, and industry restrictions here
          </Link>
        </Text>
        <FormControl mb="8rem" isInvalid={!!errors.documentTerms}>
          <Controller
            control={control}
            name="documentTerms"
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                <Checkbox ref={ref} value={"terms"} alignItems="flex-start">
                  I have read and understood the Product Information and FSCS Info Sheet and
                  Exclusion List and read, understood and agree to be bound by the Terms and
                  Conditions.
                </Checkbox>
              </CheckboxGroup>
            )}
            rules={{
              validate: (value) => {
                if (!allDocumentsRead || !value.length) {
                  setDisplayChecklistErrors(true);
                  return "Please open, read and accept the above documents";
                }
              },
            }}
          />
          <FormErrorMessage mt="1.2rem" mb="2.4rm">
            <>{errors.documentTerms && errors.documentTerms.message}</>
          </FormErrorMessage>
        </FormControl>

        <Heading size="h4" as="h4" color="$text-01" mb="1.6rem">
          Marketing Preferences
        </Heading>
        <Text textStyle="body-03" color="$text-02" mb="4rem">
          If you would like to receive information about our other products and services and receive
          news, insights and information about events from Allica, please choose how you would like
          us to communicate with you below.{" "}
        </Text>

        <Text textStyle="body-03" color="$text-02" mb="4rem">
          You can unsubscribe from these communications at any time. For more information on how to
          manage your marketing preferences, our privacy practices, and how we are committed to
          protecting and respecting your privacy, please read our Privacy Policy.
        </Text>
        <FormControl mb="1.6rem" isInvalid={!!errors.marketingPreferences}>
          <FormLabel>
            Choose how we communicate with you.
            <Text ml="0.4rem" color="$text-03" as="abbr">
              (Optional)
            </Text>
          </FormLabel>
          <Controller
            control={control}
            name="marketingPreferences"
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                <Checkbox value="email">Email</Checkbox>
                <Checkbox value="phone">Phone</Checkbox>
                <Checkbox value="sms">Sms</Checkbox>
                <Checkbox value="mail">Mail</Checkbox>
              </CheckboxGroup>
            )}
          />
          <FormErrorMessage mt="1.2rem" mb="2.4rm">
            <>{errors.marketingPreferences && errors?.marketingPreferences?.message}</>
          </FormErrorMessage>
        </FormControl>

        <Button
          isLoading={documentStatus?.loading}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon />}
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};

export default BusinessRewardsDocuments;
