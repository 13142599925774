import { Stage, StageMVP } from "../BusinessRewards.types";

export const businessRewardsStepOrderMVP: StageMVP[] = [
  StageMVP.SIGN_UP,
  StageMVP.VERIFY,
  StageMVP.BUSINESS,
  StageMVP.APPLICANT,
  StageMVP.SUMMARY,
];
export const businessRewardsStepOrder: Stage[] = [
  Stage.SIGN_UP,
  Stage.VERIFY,
  Stage.BUSINESS,
  Stage.APPLICANT,
  Stage.DEPOSIT,
  Stage.ACCOUNT,
  Stage.DOCUMENT,
  Stage.SUMMARY,
];
