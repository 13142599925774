import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  FocusEventHandler,
} from "react";
import { Input } from "@allica/ui-react";
import { InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";
import FilteredSuggestions from "./FilteredSuggestions";

type Ref = HTMLInputElement;
interface AutoCompleteProps extends InputProps {
  suggestions: Record<string, unknown>[];
  searchButtonHandler?: (a: unknown) => void;
  onClickSuggestion?: (a: unknown) => void;
  queryChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
  isError: boolean;
  width?: string;
  rightIcon?: JSX.Element;
  reFilter?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  heapId?: {
    onEnter?: string;
    onSelect?: string;
  };
}

const AutoCompleteSearch = forwardRef<Ref, AutoCompleteProps>((props, forwardedRef) => {
  const {
    suggestions,
    searchButtonHandler,
    onClickSuggestion,
    queryChangeHandler,
    isError,
    rightIcon,
    width,
    reFilter,
    heapId,
    ...rest
  } = props;

  const searchRef = useRef<HTMLDivElement>(null);

  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<Record<string, unknown>[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [highlightedItem, setHighLightedItem] = useState(-1);
  const [isMouseFocus, setIsMouseFocus] = useState(false);

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e?.target;
    setLoading(!!value);
    setShowSuggestions(!!value);
    setQuery(value);
    queryChangeHandler?.(e);
    setHighLightedItem(0);
  };

  const handleClickSuggestion = (selectedSuggestion: Record<string, unknown>) => {
    const inputElement = searchRef.current?.children.item(0) as HTMLInputElement;
    const value = selectedSuggestion?.label as string;
    inputElement.value = value;
    setQuery(value);
    setShowSuggestions(false);
    onClickSuggestion?.(selectedSuggestion);
    setHighLightedItem(-1);
  };

  const getAutoSuggestions = (query: string) => {
    const pattern = query
      .split("")
      .map((x) => {
        return `(?=.*${x?.toLowerCase()})`;
      })
      .join("");
    const regex = new RegExp(`${pattern}`, "g");
    return suggestions?.filter?.(
      (item) => query && (item?.label as string)?.toLowerCase()?.match(regex),
    );
  };

  const keyboardNavigation = (e: KeyboardEvent) => {
    setIsMouseFocus(false);
    if (e.key === "ArrowUp") {
      if (highlightedItem) {
        setHighLightedItem(highlightedItem - 1);
      }
    }
    if (e.key === "ArrowDown") {
      if (highlightedItem <= filteredSuggestions.length - 1) {
        setHighLightedItem(highlightedItem + 1);
      } else {
        setHighLightedItem(0);
      }
    }
    if (e.key === "Enter") {
      e.preventDefault();
      if (filteredSuggestions[highlightedItem]) {
        handleClickSuggestion(filteredSuggestions[highlightedItem]);
      }
    }
    if (e.key === "Escape") {
      setShowSuggestions(false);
    }
    if (e.key === "Tab") {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    if (suggestions && Array.isArray(suggestions)) {
      let autoSuggestions = suggestions;
      if (reFilter) {
        autoSuggestions = getAutoSuggestions(query);
      }
      setShowSuggestions(true);
      setHighLightedItem(0);
      setFilteredSuggestions(autoSuggestions);
      setLoading(false);
    } else {
      setLoading(false);
      setHighLightedItem(-1);
    }
  }, [suggestions]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (searchRef?.current && !searchRef?.current?.contains(event.target as HTMLElement)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  return (
    <InputGroup ref={searchRef} zIndex="dropdown" width={width}>
      <Input
        ref={forwardedRef}
        variant="outline"
        border="0.1rem solid"
        borderColor="$ui-04"
        borderRadius="0.4rem"
        bgColor="$ui-02"
        role="combobox"
        aria-autocomplete="list"
        aria-controls="filter-list"
        aria-expanded={showSuggestions && !isError && !!query}
        {...rest}
        onChange={handleQueryChange}
        autoComplete="off"
        width={"100%"}
        onKeyDown={(e) => {
          rest?.onKeyDown?.(e);
          keyboardNavigation(e);
        }}
        data-heapid={heapId?.onEnter}
      />

      {showSuggestions && query && !isError && (
        <FilteredSuggestions
          loading={loading}
          filteredSuggestions={filteredSuggestions}
          handleClickSuggestion={handleClickSuggestion}
          highlightedItem={highlightedItem}
          setHighLightedItem={setHighLightedItem}
          isMouseFocus={isMouseFocus}
          setIsMouseFocus={setIsMouseFocus}
          dataHeapId={heapId?.onSelect}
        />
      )}

      <InputRightElement
        cursor="pointer"
        children={rightIcon || undefined}
        onClick={searchButtonHandler}
      />
    </InputGroup>
  );
});

export default AutoCompleteSearch;
