export const removeLeadingZeros = (value: string) => {
  return value.replace(/^0+/, "");
};

export const removeNonNumericCharacters = (value: string) => {
  return value.replace(/[^0-9]+/g, "");
};

export const parsePhoneNumber = (phoneNumber: string) => {
  return removeLeadingZeros(removeNonNumericCharacters(phoneNumber));
};

export const splitPhoneNumber = (number?: string) => {
  const defaultNumber = { countryCode: "", phoneNumber: "" };
  if (!number) return defaultNumber;

  const splitNumber = number.split(" ");

  switch (splitNumber.length) {
    case 2: {
      const countryCode = splitNumber[0];
      const phoneNumber = splitNumber[1];
      if (countryCode.charAt(0) !== "+") return defaultNumber;

      return { countryCode, phoneNumber: removeLeadingZeros(phoneNumber) };
    }
    case 1: {
      const value = splitNumber[0];

      if (value.charAt(0) === "+" && value.length < 6) {
        return { countryCode: value, phoneNumber: "" };
      }

      return {
        countryCode: "+44",
        phoneNumber: parsePhoneNumber(splitNumber[0]),
      };
    }
    default:
      return defaultNumber;
  }
};

export const joinPhoneNumber = (countryCode: string, phoneNumber: string) => {
  const joinedNumber = `${countryCode} ${removeLeadingZeros(phoneNumber)}`;

  return phoneNumber ? joinedNumber : "";
};

export const parseFullNumber = (fullNumber?: string) => {
  const { countryCode, phoneNumber } = splitPhoneNumber(fullNumber);
  return joinPhoneNumber(countryCode, phoneNumber);
};

export const sortDialingCodes = (
  a: { name: string; description: string },
  b: { name: string; description: string }
) => {
  if (a.description < b.description) return -1;
  if (a.description > b.description) return 1;
  return 0;
};
