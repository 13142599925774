// move to shared on updating Company/Application stages
import { ApplicantSection } from "src/shared/applicant/Applicant.types";
import { defaultApplicantSectionData } from "src/shared/applicant/Applicant.utils";
import {
  defaultBusinessDetails,
  defaultCompaniesHouseData,
} from "src/shared/company/Company.utils";

import { BusinessRewardsDataTypes } from "../BusinessRewards.types";

export const initialBusinessRewardsData: BusinessRewardsDataTypes = {
  cobApplicationId: "",
  applicationID: "",
  currentApplicationStage: null,
  questions: null,
  businessRewardsApplicationSections: {
    signUpSection: {
      emailAddress: "",
      phoneNumber: "",
      password: "",
      domain: "BRA",
    },
    companySection: {
      companiesHouseResponse: { ...defaultCompaniesHouseData },
      ...defaultBusinessDetails,
    },
    applicantSection: { ...defaultApplicantSectionData } as ApplicantSection,
    otherPartiesSection: null,
    depositSection: { depositAmount: "", depositDuration: "" },
    accountSection: {
      monthlyPaymentsIn: "",
      purposeOfAccount: [],
      sourceOfFunds: [],
    },
    documentSection: { terms: false, email: true, sms: true, phone: true, mail: true },
    summarySection: { detailsVerified: false },
    outcome: { success: false },
  },
};
